// Reference docs:
// https://websanova.com/docs/

import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import VueAuth from '@websanova/vue-auth';
import VueAuthRouterAxios from '@websanova/vue-auth/drivers/http/axios.1.x';
import VueAuthRouterDriver from '@websanova/vue-auth/drivers/router/vue-router.2.x';

// enable axios globally
Vue.use(VueAxios, axios);

// set baseURL for Eventeros API
axios.defaults.baseURL = process.env.VUE_APP_EVENTEROS_API_URL;

// enable VueAuth globally
Vue.use(VueAuth, {
  // auth driver
  // * This driver is used to parse a token from a response and format a token for a request.
  // * In it you will find the two functions aptly named request and response.
  auth: {
    // * Interceptor used for adding the Authorization header
    request(req, token) {
      // console.group('request:');
      // console.log('token', token);
      // console.log('request ', req);
      // console.log('url ', req.url);
      // console.log('base url', req.baseURL);
      // console.groupEnd();
      // if (req.url.lastIndexOf(process.env.VUE_APP_EVENTEROS_API_URL, 0) === 0) {
      //   console.log('adding Authorization ... ');
      //   req.headers.Authorization = `Bearer ${token}`;
      // }
      const request = req;
      if (request.baseURL === process.env.VUE_APP_EVENTEROS_API_URL) {
        request.headers.Authorization = `Bearer ${token}`;
      }
    },
    response(res) {
      // Interceptor to get the "access token" from response body
      if (res.data.access_token) {
        // console.group('response:');
        // console.log('* url =>', res.config.url);
        // console.log('* data => ', res.data);
        // console.log('* access_token => ', res.data.access_token);
        // console.groupEnd();
        return res.data.access_token;
      }
      return undefined;
    },
  },
  // http driver
  http: VueAuthRouterAxios,
  // router driver
  router: VueAuthRouterDriver,
  // redirect to login when route is protected
  authRedirect: { path: '/login' },
  // options
  loginData: {
    url: '/login/refresh?login',
    method: 'POST',
    redirect: '/',
    fetchUser: true,
  },
  logoutData: {
    url: '/logout',
    method: 'POST',
    redirect: '/',
    makeRequest: true,
  },
  refreshData: {
    url: '/login/refresh',
    method: 'POST',
    enabled: true,
    interval: 30,
  },
  fetchData: {
    url: '/api/v1/user',
    method: 'GET',
    enabled: true,
  },
  parseUserData(data) {
    return data;
  },
});
