<template>
  <e-multi-select
    :name="name"
    :rules="rules"
    :data="services"
    :label="label"
    :max-items="maxItems"
    :hide-details="hideDetails"
    item-text="type_name"
    item-value="id"
    v-model="innerValue"
    v-on="$listeners">
    <!-- Pass on all named slots -->
    <slot
      v-for="slot in Object.keys($slots)"
      :name="slot"
      :slot="slot"
    />
    <!-- Pass on all scoped slots -->
    <template
      v-for="slot in Object.keys($scopedSlots)"
      :slot="slot"
      slot-scope="scope">
      <slot :name="slot" v-bind="scope"/>
    </template>
    <!-- item slot -->
    <template
      slot="item"
      slot-scope="{ item, attrs, on }">
      <v-list-item v-on="on" v-bind="attrs" #default="{ active }">
        <template
          v-if="item.type === 'header'">
          <span
            class="grey--text">
            {{ item['group_name'] }}
          </span>
        </template>
        <template
          v-else>
          <v-list-item-action>
            <v-checkbox :input-value="active"></v-checkbox>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              <v-row no-gutters align="center">
                <span>{{ item["type_name"] }}</span>
              </v-row>
            </v-list-item-title>
          </v-list-item-content>
        </template>
      </v-list-item>
    </template>
  </e-multi-select>
</template>

<script>

import services from '@/mixins/services';

export default {
  name: 'MultiSelectServices',
  mixins: [services],
  inheritAttrs: false,
  props: {
    selectedCategories: {
      type: Array,
      required: true,
      default: () => [],
    },
    name: {
      type: String,
      required: false,
      default: 'services',
    },
    label: {
      type: String,
      required: false,
      default: 'Seleccionar servicios',
    },
    value: null,
    rules: [String],
    maxItems: {
      type: Number,
      required: false,
      default: 1,
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    services() {
      const groups = (this.selectedCategories.length > 0
        ? this.selectedCategories
        : this.categories)
        .map((p) => {
          const group = {
            type: 'header',
            group_name: p.group_name,
            disabled: true,
          };
          return group;
        });
      const customItems = [];
      groups.forEach((group) => {
        customItems.push(group);
        customItems.push(...this.getServicesByGroup(group.group_name));
      });
      return customItems;
    },
  },
  data() {
    return {
      innerValue: this.value,
    };
  },
  watch: {
    value(val) {
      this.innerValue = val;
    },
  },
};
</script>
