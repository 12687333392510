<template>
  <section
    class="box-shadow"
    :class="{
      'full-height': fullHeight,
      'no-border': noBorder,
      'no-elevation': noElevation,
    }"
    :style="`
      background-color: ${backgroundColor};
      border-radius: ${borderRadius}!important;
    `">
    <slot></slot>
  </section>
</template>

<script>
export default {
  name: 'BoxShadow',
  props: {
    backgroundColor: {
      type: String,
      default: 'white',
    },
    borderRadius: {
      type: String,
      default: '6px',
    },
    fullHeight: {
      type: Boolean,
      default: false,
    },
    noBorder: {
      type: Boolean,
      default: false,
    },
    noElevation: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.box-shadow {
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border: solid 1px #eee;

  &.full-height {
    height: 100%;
  }

  &.no-border {
    border: none!important;
  }

  &.no-elevation {
    box-shadow: none!important;
  }
}
</style>
