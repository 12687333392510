<template>
  <div
    class="wizard-stepper"
    :class="mainColor"
    v-if="steps.length > 0">
    <e-title
      class="stepper-title">
      {{ title }}
    </e-title>
    <div
      class="stepper-wrapper">
      <v-stepper
        v-model="step"
        alt-labels
        class="rounded-0 elevation-0 transparent">
        <v-stepper-header>
          <template
            v-for="(item, index) in steps">
            <v-stepper-step
              :key="index"
              :step="index + 1"
              :complete="step > index + 1">
              <div>
                {{item}}
              </div>
            </v-stepper-step>
          </template>
        </v-stepper-header>
      </v-stepper>
      <v-divider></v-divider>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WizardStepper',
  props: {
    currentStep: {
      default: 1,
    },
    steps: {
      default: [],
    },
    title: {
      type: String,
      default: 'Título',
    },
    mainColor: {
      default: 'brand_purple', // brand_blue
    },
  },
  data() {
    return {
      step: this.currentStep,
    };
  },
  watch: {
    currentStep() {
      this.step = this.currentStep;
    },
  },
};
</script>

<style lang="scss">
  .wizard-stepper {
    width: 100%;
    max-width: 100%;
    margin: 0;
    justify-content: center;
    position: relative;
    padding: 10px 120px 15px;

    .stepper-wrapper {
      position: relative;
    }

    .stepper-title {
      color: white;
      text-align: center;
      margin-bottom: 6px;
    }

    .v-stepper__header {
      box-shadow: none !important;
    }

    .v-stepper__label {
      margin-top: 4px;
      font-size: 0.9rem!important;
      color: white!important;
      display: inline!important;
    }

    .v-stepper__step {
      z-index: 1;
      padding: 0px;
      background-color: transparent;
      flex-basis: 100px;
    }

    &.brand_purple {
      background-color: $brand-purple-lighten-1;

      $active: $brand-blue-darken-1!default;
      $complete: $brand-purple-lighten-2!default;
      $inactive: #E6E9F0!default;

      .v-stepper__step.v-stepper__step--active {
        .v-stepper__step__step {
          background: $active!important;
        }
      }

      .v-stepper__step.v-stepper__step--complete {
        .v-stepper__step__step {
          background: $complete!important;
        }
      }

      .v-stepper__step:not(.v-stepper__step--active):not(.v-stepper__step--complete) {
        .v-stepper__step__step {
          color: $brand-purple-darken-1;
          background: $inactive!important;
        }
      }
    }

    &.brand_blue {
      background-color: $brand-blue;

      $active: $brand-purple-lighten-1!default;
      $complete: $brand-blue-lighten-2!default;
      $inactive: #E6E9F0!default;

      .v-stepper__step.v-stepper__step--active {
        .v-stepper__step__step {
          background: $active!important;
        }
      }

      .v-stepper__step.v-stepper__step--complete {
        .v-stepper__step__step {
          background: $complete!important;
        }
      }

      .v-stepper__step:not(.v-stepper__step--active):not(.v-stepper__step--complete) {
        .v-stepper__step__step {
          color: $brand-blue-darken-1;
          background: $inactive!important;
        }
      }
    }

    .v-stepper__step__step {
      width: 40px;
      height: 40px;
      font-size: 0.95rem;
      font-weight: 700;
      margin-bottom: 0;
    }

    .v-divider {
      position: absolute;
      width: calc(100% - 60px);
      top: 20px;
      left: 30px;
      z-index: 0;
      border-color: #e3e3e3;
      border-style: dotted;
    }

    @media screen and (max-width: 960px) {
      padding-left: 80px;
      padding-right: 80px;

      .v-stepper__step {
        flex-basis: 70px;
      }
    }

    @media screen and (max-width: 600px) {
      padding-left: 40px;
      padding-right: 40px;

      .v-stepper__header {
        flex-wrap: nowrap;
      }

      .v-stepper__step {
        flex-basis: 30px;
      }

      .v-stepper__step__step {
        width: 30px;
        height: 30px;
        font-size: 0.9rem;
      }

      .v-divider {
        top: 15px;
      }

      .v-stepper:not(.v-stepper--vertical)
      .v-stepper__label {
        display: none!important;
      }

    }
  }
</style>
