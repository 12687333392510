<template>
  <div class="wizard-done-line box">
    <div
      v-if="label"
      class="line-label">
      {{ label }}
    </div>
    <div
      class="line-value"
      :class="contentClass">
      <template v-if="value">{{ value }}</template>
      <slot name="photos"></slot>
      <slot name="custom"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WizardDoneLine',
  props: {
    label: {
      type: String,
      default: null,
    },
    value: {
      type: String,
      default: null,
    },
    contentClass: {
      type: String,
      default: null,
    },
  },
};
</script>

<style lang="scss">
.wizard-done-line {
  flex: 1 0 auto;
  overflow-y: auto;

  &.box {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    gap: 4px;
  }

  .line-label {
    color: darkgray;
  }
}
</style>
