<template>
  <div class="page-size-selector"
    :style="`width: ${width}`">
    <span v-if="label">{{ label }}</span>
    <v-select
      :items="options"
      flat
      solo
      filled
      dense
      hide-details
      class="page-size-select"
      v-model="innerValue">
      <template v-slot:item="{item}">
        <div
          style="width: 100%"
          class="text-center">
          {{ item }}
        </div>
      </template>
    </v-select>
  </div>
</template>

<script>
export default {
  name: 'SelectPageSize',
  props: {
    label: {
      type: String,
      default: 'Mostrar',
    },
    options: {
      type: Array,
      default: () => [10, 20, 50],
    },
    value: {
      type: Number,
      default: 10,
    },
    width: {
      type: String,
      default: '140px',
    },
  },
  data() {
    return {
      innerValue: this.value,
    };
  },
  watch: {
    value() {
      this.innerValue = this.value;
    },
    innerValue() {
      this.$emit('input', this.innerValue);
    },
  },
};
</script>

<style lang="scss">
.page-size-selector {
  display: flex;
  align-items: center;
  gap: 8px;

  .page-size-select {
    &.v-select {
      .v-input__control {
        border-radius: 64px;
      }

      .v-select__selections {
        justify-content: center;

        input {
          display: none;
        }

        .v-select__selection--comma {
          margin: 0!important;
        }
      }

      .v-input__append-inner {
        padding: 0!important;
      }
    }
  }
}
</style>
