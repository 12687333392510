const mixin = {
  computed: {
    enableFullscreen() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  methods: {
    isFullScreen() {
      return window.navigator.standalone
        || (document.fullScreenElement && document.fullScreenElement !== null)
        || (document.mozFullScreen || document.webkitIsFullScreen);
    },
    enterFullscreen() {
      if (this.enableFullscreen && !this.isFullScreen()) {
        const element = document.documentElement;
        if (element.requestFullscreen) {
          element.requestFullscreen();
        } else if (element.mozRequestFullScreen) {
          element.mozRequestFullScreen();
        } else if (element.webkitRequestFullscreen) {
          element.webkitRequestFullscreen();
        } else if (element.msRequestFullscreen) {
          element.msRequestFullscreen();
        }
      }
    },
    exitFullscreen() {
      if (this.enableFullscreen && this.isFullScreen()) {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
      }
    },
  },
};

export default mixin;
