/* eslint-disable quote-props */
export default {
  'locals': 'Locales',
  'locals.housesandgardens': 'Casas y jardines',
  'locals.hotels': 'Hoteles',
  'locals.restaurants': 'Restaurantes',
  'locals.eventrooms': 'Salones',
  'locals.conventions': 'Centro de convenciones',
  'locals.auditoriums': 'Auditorios',
  'locals.recreational': 'Centros recreacionales y campestres',

  'feeding': 'Alimentación',
  'feeding.catering': 'Catering y buffets',
  'feeding.drinks': 'Bebidas y licores',
  'feeding.cakes': 'Tortas',
  'feeding.kidscatering': 'Catering infantil',
  'feeding.fooddelivery': 'Comida a domicilio',

  'audiovisual': 'Producción audiovisual',
  'audiovisual.lightingandsound': 'Iluminación y sonido',
  'audiovisual.dj': 'DJ',
  'audiovisual.screening': 'Proyección audiovisual',
  'audiovisual.photoandvideo': 'Fotografía y video',
  'audiovisual.gensets': 'Grupos electrógenos',

  'animation': 'Animación',
  'animation.animators': 'Maestros de ceremonia y presentadores',
  'animation.artists': 'Artistas y humoristas',
  'animation.shows': 'Espectáculos para eventos',
  'animation.bands': 'Orquestas',
  'animation.kidsshows': 'Shows infantiles',
  'animation.crazyhour': 'Hora loca',
  'animation.mariachis': 'Mariachis',
  'animation.hostess': 'Anfitrionaje',
  'animation.gymkanasandgames': 'Gymkanas y juegos',
  'animation.fireworks': 'Fuegos artificiales',
  'animation.chorus': 'Coro',

  'dressing': 'Vestuario y maquillaje',
  'dressing.weddingdress': 'Vestidos de novia',
  'dressing.costumes': 'Vestuario y disfraces',
  'dressing.makeupandhairstyle': 'Maquillaje y peinado',

  'planning': 'Organización',
  'planning.invitations': 'Invitaciones',
  'planning.registration': 'Registro y acreditaciones',
  'planning.merchandising': 'Regalos y merchandising',
  'planning.promotion': 'Promoción y publicidad ',
  'planning.eventplaning': 'Organización de eventos',
  'planning.weddingplanner': 'Wedding planner',
  'planning.transport': 'Transporte y valet parking',
  'planning.security': 'Seguridad ',
  'planning.medicalcare': 'Atención médica',
  'planning.translators': 'Traductores e intérpretes',

  'setting': 'Ambientación',
  'setting.eventdecoration': 'Decoración de eventos',
  'setting.kidsdecoration': 'Decoración infantil',
  'setting.flowers': 'Arreglos florales',
  'setting.utensils': 'Mantelería y menaje',
  'setting.partyfavors': 'Globos y cotillón',
  'setting.bannersandbillboards': 'Banderolas y gigantografías',

  'structures': 'Estructuras',
  'structures.scenarios': 'Escenarios ',
  'structures.awnings': 'Toldos',
  'structures.standsandmodules': 'Stands y módulos',
  'structures.grandstands': 'Tribunas',
  'structures.furniture': 'Mesas y muebles',
  'structures.dancefloor': 'Pistas de baile',
  'structures.bars': 'Barras ',
  'structures.portablestoilets': 'Sanitarios portátiles',

  // Captions
  'commingsoon': 'Próximamente',

  'eventeros.text-caption': 'los eventeros',
  'eventeros.slogan': 'Una comunidad de expertos en el mundo de los eventos',
  'eventeros.description': 'Interactúa, comparte, aprende y respira el mundo de los eventos. Accede a esta gran comunidad llena de nuevas ideas, información, conocimiento, experiencia y mucha creatividad, disponible para ti en el momento y lugar que lo necesites.',
  'eventeros.action': '&iexcl;Quiero ser parte de esta comunidad!',

  'data.text-caption': 'los datos',
  'data.slogan': 'Información útil para tomar mejores decisiones',
  'data.description': 'Mantente actualizado, identifica las nuevas tendencias y accede a información útil para analizar el mercado de los eventos. Conoce más a tu clientes y mide el impacto que tienen productos y servicios.',
  'data.action': '&iexcl;Me interesa esta información!',

  'apps.text-caption': 'los apps',
  'apps.slogan': 'Herramientas increíbles para gestionar mejor tus eventos',
  'apps.description': 'Accede a un paquete de herramientas de software moderno, innovador, fácil de usar y de gran utilidad. Disfruta las ventajas de vivir en el mundo digital.',
  'apps.action': '&iexcl;Yo también quiero usarlas!',

};
