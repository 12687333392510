<template>
  <v-img
    aspect-ratio="1"
    :src="user.avatar"
    :lazy-src="user.avatar"
    :alt="user.first_name"
    :width="size"
    :height="size"
    :class="circle ? 'rounded-circle' : !flat ? 'rounded' : ''">
    <template #placeholder>
      <v-row
        class="fill-height ma-0"
        align="center"
        justify="center">
        <v-progress-circular
          indeterminate
          color="grey lighten-5"
        ></v-progress-circular>
      </v-row>
    </template>
  </v-img>
</template>

<script>
export default {
  name: 'AvatarUser',
  props: {
    user: {
      type: Object,
      default: null,
    },
    size: {
      type: Number,
      default: 42,
    },
    circle: {
      type: Boolean,
      default: true,
    },
    flat: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
