<template>
  <div
    class="e-file-input">
    <e-text-field
      :name="name"
      :rules="rules"
      :mode="mode"
      :placeholder="placeholder"
      :readonly="true"
      :value="fileName"
      :hide-details="hideDetails">
      <template v-slot:append>
        <e-button
          label="Buscar"
          :outlined="true"
          color="grey"
          @click="searchFile"
        ></e-button>
      </template>
    </e-text-field>
    <ValidationProvider
      ref="fileProvider"
      :name="`${name}_file`"
      :rules="`size:${maxSize}`"
      v-slot="{ errors }">
      <input
        ref="inputFile"
        type="file"
        @change="handleFileSelect"
        :accept="acceptedTypes"
        v-show="false"
      >
      <field-error
        v-if="errors[0]"
        class="mt-2">
        {{ errors[0] }}
      </field-error>
    </ValidationProvider>
  </div>
</template>

<script>

const supportedTypes = Object.freeze({
  PDF: [
    '.pdf',
    'application/pdf',
  ],
  EXCEL: [
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  ],
  WORD: [
    '.doc',
    '.docx',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  ],
});

export default {
  name: 'FileInput',
  inheritAttrs: false,
  props: {
    name: null,
    rules: [String],
    mode: {
      type: String,
      default: 'eager',
    },
    placeholder: {
      type: String,
      default: 'Seleccionar archivo',
    },
    fileTypes: {
      type: [String, Array],
      default: () => ['pdf'],
      validator: (value) => {
        if (typeof value === 'string') {
          return Object.keys(supportedTypes).includes(value.toUpperCase());
        }
        return value.every((val) => Object.keys(supportedTypes).includes(val.toUpperCase()));
      },
    },
    maxSize: {
      type: String,
      default: '1024', // 1MB
    },
  },
  data() {
    return {
      file: null,
      sizeError: false,
    };
  },
  computed: {
    acceptedTypes() {
      let types = [];
      if (typeof this.fileTypes === 'string') {
        types.push(this.fileTypes);
      } else {
        types = [...this.fileTypes];
      }
      return types
        .map((p) => {
          const mimeType = supportedTypes[p.toUpperCase()];
          return mimeType.join(', ');
        }).join(', ');
    },
    fileName() {
      if (this.file) return this.file.name;
      return null;
    },
    fileError() {
      if (this.$refs.fileProvider?.errors.length === 0) return null;
      return this.$refs.fileProvider?.errors[0];
    },
    hideDetails() {
      return this.sizeError;
    },
  },
  methods: {
    searchFile() {
      this.$refs.inputFile.click();
    },
    async handleFileSelect(event) {
      await this.$refs.fileProvider.validate(event);
      this.sizeError = this.$refs.fileProvider?.errors.length > 0;
      [this.file] = event.target.files;
      this.$emit('input', this.file);
    },
  },
};
</script>

<style lang="scss">
.e-file-input {
  .v-text-field--enclosed .v-input__append-inner {
    margin-top: 10px!important;
  }
  input::placeholder {
    color: #00000099!important;
  }
}

</style>
