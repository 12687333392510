<template>
  <v-menu
    v-model="menu"
    transition="scale-transition"
    offset-y
    max-width="290px"
    min-width="auto"
    :nudge-right="20"
    :close-on-content-click="false">
    <template v-slot:activator="{ on }">
      <e-text-field
        v-on="on"
        :mode="mode"
        :name="name"
        :rules="rules"
        :label="label"
        :filled="true"
        :readonly="true"
        v-model="formattedDate">
        <!-- pass named slots -->
        <template
          v-for="(slot, name) in $slots"
          :slot="name">
          <slot v-if="name === 'default'"></slot>
          <slot v-if="name !== 'default'" :name="name"></slot>
        </template>
      </e-text-field>
    </template>
    <v-date-picker
      v-on="$listeners"
      :no-title="noTitle"
      :scrollable="scrollable"
      :locale="locale"
      :min="min"
      @input="menu = false"
      v-model="innerValue">
    </v-date-picker>
  </v-menu>
</template>

<script>
export default {
  name: 'DatePicker',
  props: {
    value: null,
    noTitle: {
      type: Boolean,
      default: true,
    },
    scrollable: {
      type: Boolean,
      default: true,
    },
    locale: {
      type: String,
      default: 'es-pe',
    },
    min: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      required: false,
      default: 'Seleccionar fecha',
    },
    name: {
      type: String,
      required: true,
      default: null,
    },
    rules: [String],
    mode: {
      type: String,
      default: 'eager',
    },
    formatString: {
      type: String,
      default: 'DD/MM/YYYY',
    },
  },
  data() {
    return {
      menu: false,
      innerValue: this.value,
      formattedDate: this.format(this.value),
    };
  },
  watch: {
    value(val) {
      this.innerValue = val;
    },
    innerValue(val) {
      this.formattedDate = this.format(val);
    },
  },
  methods: {
    format(date) {
      // https://momentjs.com/docs/#/displaying/format/
      // short: DD/MM/YYYY
      // long: dddd, LL
      return date
        ? this.$moment(date).format(this.formatString)
        : null;
    },
  },
};
</script>
