<template>
  <v-btn
    class="e-button"
    :outlined="outlined"
    :text="text"
    :color="color"
    :width="fullWidth ? '100%' : undefined"
    v-on="$listeners">
    <v-icon
      size="17"
      v-if="leftIcon"
      class="mr-1">
      {{ leftIcon }}
    </v-icon>
    <slot>
      <span :style="`color: ${labelColor};`">
        {{ label }}
      </span>
    </slot>
    <v-icon
      size="17"
      v-if="rightIcon"
      class="ml-1">
      {{ rightIcon }}
    </v-icon>
  </v-btn>
</template>

<script>
export default {
  name: 'Button',
  props: {
    label: {
      type: String,
      default: 'Botón',
    },
    text: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: 'brand-green-darken-1',
    },
    labelColor: {
      type: String,
      default: 'inherit',
    },
    leftIcon: {
      type: String,
      default: null,
    },
    rightIcon: {
      type: String,
      default: null,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss">
.e-button {
  padding: 0 20px!important;
  font-weight: 600!important;
}
</style>
