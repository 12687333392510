<template>
  <ValidationProvider
    :mode="mode"
    :name="name"
    :rules="rules"
    v-slot="{ errors }"
    class="e-textarea-wrapper"
    :class="{'full-height': fullHeight}">
    <v-textarea
      v-on="$listeners"
      v-model="innerValue"
      :outlined="!filled"
      :filled="filled"
      :auto-grow="autoGrow"
      :persistent-hint="persistentHint"
      :label="label"
      :counter="maxLength"
      :maxlength="maxLength"
      :rows="rows"
      :row-height="rowHeight"
      :hide-details="hideDetails"
      :full-width="fullWidth"
      :readonly="readonly"
      :clearable="clearable"
      :no-resize="noResize"
      :autofocus="autofocus"
      :class="customClass"
      :error-messages="errors[0]"
      class="e-textarea">
      <!-- pass named slots -->
      <template
        v-for="(slot, name) in $slots"
        :slot="name">
        <slot v-if="name === 'default'"></slot>
        <slot v-if="name !== 'default'" :name="name"></slot>
      </template>
      <!-- pass scoped slots -->
      <template
        v-for="(slot, name) in $scopedSlots"
        :slot="name"
        slot-scope="props">
        <slot :name="name" v-bind="props"></slot>
      </template>
    </v-textarea>
  </ValidationProvider>
</template>

<script>
export default {
  name: 'Textarea',
  inheritAttrs: false,
  props: {
    name: null,
    value: null,
    rules: [String],
    mode: {
      type: String,
      default: 'eager',
    },
    label: {
      type: String,
      default: 'Descripción',
    },
    maxLength: {
      type: Number,
      default: 1000,
    },
    rows: {
      type: Number,
      default: 4,
    },
    rowHeight: {
      type: Number,
      default: 34,
    },
    fullHeight: {
      type: Boolean,
      default: false,
    },
    filled: {
      type: Boolean,
      default: true,
    },
    autoGrow: {
      type: Boolean,
      default: true,
    },
    persistentHint: {
      type: Boolean,
      default: true,
    },
    customClass: {
      type: String,
      default: '',
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    noResize: {
      type: Boolean,
      default: false,
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      innerValue: this.value,
    };
  },
  watch: {
    value(val) {
      this.innerValue = val;
    },
  },
};
</script>

<style lang="scss">
.e-textarea-wrapper {
  .e-textarea {
    .v-messages__message,
    .v-counter {
      line-height: 14px;
    }
  }

  // full-height
  &.full-height {
    display: flex;
    height: 100%;

    .e-textarea {
      flex: 1;

      .v-input__control {
        height: 100%;
        .v-input__slot {
          flex: 1;
        }
        .v-text-field__details {
          flex: 0 0 18px;
        }
      }
    }
  }
}
</style>
