import Vue from 'vue';
import WizardStepper from './WizardStepper.vue';
import WizardStepperModal from './WizardStepperModal.vue';
import WizardStep from './WizardStep.vue';
import WizardDone from './WizardDone.vue';
import WizardDoneLine from './WizardDoneLine.vue';

Vue.component('wizard-stepper', WizardStepper);
Vue.component('wizard-stepper-modal', WizardStepperModal);
Vue.component('wizard-step', WizardStep);
Vue.component('wizard-done', WizardDone);
Vue.component('wizard-done-line', WizardDoneLine);
