<template>
  <div
    class="wizard-done">
    <div class="title" v-html="title"></div>
    <div class="image">
      <v-img
        :max-width="svgWidth"
        :src="`https://eventeros.b-cdn.net/static/svg/${svg}`">
      </v-img>
    </div>
    <div class="message" v-html="message"></div>
    <div>
      <v-btn
        class="px-6 brand-green-darken-1 white--text"
        @click="$emit('click', $event)">
        {{ ctaLabel }}
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WizardDone',
  props: {
    title: {
      type: String,
      default: '',
    },
    svg: {
      type: String,
      default: 'well-done.svg',
    },
    svgWidth: {
      type: Number,
      default: 150,
    },
    message: {
      type: String,
      default: '',
    },
    ctaLabel: {
      type: String,
      default: 'Continuar',
    },
  },
};
</script>

<style lang="scss" scoped>

.wizard-done {
  padding: 42px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .title {
    font-family: "Work Sans", sans-serif !important;
    font-weight: 700;
    font-size: 1.8rem!important;
    line-height: 2.5rem;
  }

  .image {
    display: flex;
    justify-content: center;
    margin: 24px;
  }

  .message {
    padding: 0 42px 42px;
    font-size: 1.2rem!important;
  }

  @media screen and (max-width: 600px) {
    padding: 0 20px;

    .title {
      font-size: 1.6rem!important;
    }

    .message {
      padding: 0 0 32px;
      font-size: 1.07rem!important;
    }
  }
}

</style>
