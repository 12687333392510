var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{key:_vm.modalKey,attrs:{"width":_vm.width,"persistent":_vm.persistent,"transition":"scale-transition","content-class":("modal-dialog " + _vm.contentClass)},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.close($event)}},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var errors = ref.errors;
return [_c('form',{style:(("height: " + _vm.height)),on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('div',{staticClass:"modal-left"},[_vm._t("left")],2),_c('div',{staticClass:"modal-form pa-0",class:{
          'no-header': _vm.noHeader,
          'no-actions': _vm.noActions,
          'no-gutter': _vm.noGutter
        }},[_c('div',{staticClass:"header"},[_c('e-title',{staticClass:"modal-title",attrs:{"font-size":"1.4rem"}},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('v-btn',{staticStyle:{"top":"8px","right":"8px"},attrs:{"absolute":"","small":"","text":"","fab":"","top":"","right":"","color":"grey","disabled":_vm.waiting},nativeOn:{"click":function($event){return _vm.close($event)}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('div',{ref:"content",staticClass:"content nice-scrollbar",style:(("overflow: " + (_vm.scrollable ? 'auto' : 'hidden')))},[_vm._t("default"),(_vm.showErrors)?_c('form-error',{attrs:{"errors":errors},on:{"toogleVisible":_vm.scrollToEnd}}):_vm._e()],2),(_vm.noActions == false)?_c('div',{staticClass:"actions"},[_vm._t("actions",[_c('v-spacer'),_c('v-btn',{staticClass:"grey--text px-10",attrs:{"depressed":"","text":"","right":"","disabled":_vm.waiting},nativeOn:{"click":function($event){return _vm.close($event)}}},[_vm._v(" Cancelar ")]),_c('v-btn',{staticClass:"white--text px-10",class:_vm.submitColor,attrs:{"right":"","type":"submit","loading":_vm.waiting}},[_vm._v(" "+_vm._s(_vm.submitLabel)+" ")])])],2):_vm._e()]),_c('div',{staticClass:"modal-right"},[_vm._t("right")],2)])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }