<template>
  <v-snackbar
    v-model="show"
    bottom
    :color="snackbar.color"
    :timeout="snackbar.timeout"
  >
    <div>
      <span v-html="snackbar.text" />
    </div>

    <template #action="{ attrs }">
      <v-btn
        dark
        text
        v-bind="attrs"
        @click="close"
      >
        Cerrar
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>

import { mapActions, mapState } from 'vuex';

export default {
  name: 'Snackbar',
  data() {
    return {
      show: false,
    };
  },
  watch: {
    show(newValue) {
      if (newValue === false) {
        this.hideSnackbar();
      }
    },
    snackbar: {
      handler(newValue) {
        this.show = newValue.show;
      },
      deep: true,
    },
  },
  computed: {
    ...mapState('app/', {
      snackbar: 'snackbar',
    }),
  },
  methods: {
    ...mapActions('app/', ['hideSnackbar']),
    close() {
      this.show = false;
    },
  },
};
</script>
