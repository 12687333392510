import axios from 'axios';

const ENVIRONMENT = process.env.VUE_APP_ENV;
const UPLOAD_GAPPS_URL = process.env.VUE_APP_UPLOAD_GAPPS_URL;
const LOADIMAGE_GAPPS_URL = process.env.VUE_APP_LOADIMAGE_GAPPS_URL;

export default {
  /**
   * @param {string} fileName
   * @param {string} dataUrl
   * @param {string} albumId
   */
  uploadPhoto(fileName, dataUrl, albumId) {
    const path = `media/${albumId}`;
    return this.uploadMedia(fileName, dataUrl, path);
  },
  /**
  * @param {string} fileName
  * @param {string} dataUrl
  */
  uploadLogo(fileName, dataUrl) {
    const path = 'logotipos';
    return this.uploadMedia(fileName, dataUrl, path);
  },
  /**
  * @param {string} fileName
  * @param {string} dataUrl
  */
  uploadAvatar(fileName, dataUrl) {
    const path = 'avatars';
    return this.uploadMedia(fileName, dataUrl, path);
  },
  /**
   * @param {string} fileName
   * @param {string} dataUrl
   * @param {string} path
   */
  uploadMedia(fileName, dataUrl, path) {
    // upload photo to CDN
    return new Promise((resolve, reject) => {
      const mimeType = (dataUrl.match(/^.*(?=;)/)[0]).replace('data:', '');
      const data = dataUrl.replace(/^.*,/, '');

      // console.group('Upload logo to Bunny CDN:');
      // console.log('path', path);
      // console.log('mimeType', mimeType);
      // console.log('data', data);
      // console.groupEnd();

      // create formData
      const formData = new FormData();
      formData.append('path', path);
      formData.append('mime_type', mimeType);
      formData.append('data', data);

      axios.post('/api/v1/cdn/upload/media', formData, null)
        .then((response) => {
          // return uploaded file { id, url }
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  uploadPdf(file, path) {
    return new Promise((resolve, reject) => {
      // create formData
      const formData = new FormData();
      formData.append('path', path);
      formData.append('file', file);

      axios.post('/api/v1/cdn/upload/pdf', formData, null)
        .then((response) => {
          // return uploaded file { id, url }
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  delete(path, filename) {
    // console.group('Delete file from Bunny CDN:');
    // console.log('path', path);
    // console.log('filename', filename);
    // console.groupEnd();

    const params = {
      path,
      filename,
    };

    return axios.delete('/api/v1/cdn/delete', { params });
  },
  purge(url) {
    // console.group('Purge file from Bunny CDN:');
    // console.log('absolute url', url);
    // console.groupEnd();

    const params = {
      url,
    };

    return axios.delete('/api/v1/cdn/purge', { params });
  },
  /**
   * @param {string} fileName
   * @param {string} dataUrl
   * @param {string} fileType
   */
  uploadMediaToGoogleDrive(fileName, dataUrl, fileType) {
    // upload photo to Google Drive repository
    return new Promise((resolve, reject) => {
      const validFileTypes = ['Media', 'Logotipos'];
      if (!validFileTypes.includes(fileType)) {
        reject(new Error('File type invalid'));
      }

      // logging metadata
      // console.group('Upload logo to Google Drive:');
      // console.log('file name', fileName);
      // console.log('file type', fileType);
      // console.log('environment', ENVIRONMENT);
      // console.log('upload gapps url', UPLOAD_GAPPS_URL);
      // console.groupEnd();

      // create formData will send to Google App Script
      const formData = new FormData();
      formData.append('data', dataUrl.replace(/^.*,/, ''));
      formData.append('mimeType', dataUrl.match(/^.*(?=;)/)[0]);
      formData.append('filename', fileName);
      formData.append('environment', ENVIRONMENT);
      formData.append('filetype', fileType);

      // Luis Rodriguez 29-05-2019
      // After upgrade Vue, any request to google app script to
      // upload media was blocked by CORS policy
      // Solution:
      //  1. Create a clean axios instance (not use global instance).
      //     Reference: https://github.com/axios/axios
      //  2. Not send any header in Http request.
      //  3. Add a MimeType.JAVASCRIPT to google app scripts response. See comments in script.

      const instance = axios.create({
        timeout: 60000,
      });
      instance.post(UPLOAD_GAPPS_URL, formData, null)
        .then((response) => {
          // return google drive ID
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  loadImageFromGoogleDrive(fileId) {
    // upload photo to Google Drive repository
    return new Promise((resolve, reject) => {
      // use Google App Scripts
      const instance = axios.create({
        timeout: 120000,
      });

      const url = `${LOADIMAGE_GAPPS_URL}?fileId=${fileId}`;

      instance.get(url)
        .then((response) => {
          // return google drive ID
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
