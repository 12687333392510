export default {
  // introductory text
  'provider.introductory_text': {
    min: (_, { length }) => `Ingresa una descripción más larga (por los menos ${length} caracteres). Esto ayudará a nuestros usuarios a conocer mejor tu empresa y los animará a explorar tu sitio web.`,
  },
  // services
  'services.selector': {
    min_value: 'Selecciona al menos un servicio de la lista',
  },
  // user events
  'userevent.description': {
    min: (_, { length }) => `Ingresa una descripción más larga (por los menos ${length} caracteres). Esto ayudará a los proveedores a entender lo que necesitas y a enviarte una mejor cotización.`,
  },
  // reviews
  'review.criterias': {
    min_value: 'Califica todos los criterios',
  },
  'review.text': {
    required: 'Ingresa tu experiencia con este proveedor',
    min: (_, { length }) => `Ingresa un comentario más largo (por lo menos ${length} caracteres). Esto nos ayudará a todos a conocer mejor tu experiencia.`,
  },
  'review.conditions': {
    required: 'Acepta la Política de Contenido',
  },
  'review.cancel_reason': {
    required: 'Por favor, ingresa tus razones',
  },
  'review.response': {
    required: 'Por favor, ingresa tu respuesta',
  },
};
