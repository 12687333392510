import Vue from 'vue';

import userController from '@/controllers/User';
import searchController from '@/controllers/Search';
import locationController from '@/controllers/Location';
import providerController from '@/controllers/Provider';
import eventTypeController from '@/controllers/EventType';
import serviceTypeController from '@/controllers/ServiceType';

import VueRouter from '../../router';
import * as types from '../mutation-types';

const refreshIfEmpty = async ({ commit, state }, type, endpoint, obj) => {
  if (obj) { // if pass obj, update
    commit(type, obj);
  }

  let isEmpty = true;
  if (Array.isArray(state[type])) {
    isEmpty = state[type].length === 0;
  } else if (typeof state[type] === 'number') {
    isEmpty = state[type] === 0;
  } else if (typeof state[type] === 'string') {
    isEmpty = state[type] === '';
  }
  if (isEmpty) {
    const response = await endpoint();
    if (response.data) {
      commit(type, response.data);
    } else {
      commit(type, response);
    }
  }
};

export default {
  namespaced: true,
  state: {
    eventTypes: [],
    eventTypesTop: [],
    serviceTypes: [],
    locations: [],
    selectedServiceGroups: [],
    selectedServiceTypes: [],
    selectedEventTypes: [],
    selectedLocations: [],
    quotation: null,
    newProviders: [],
    featuredProviders: [],
    countProviders: 0,
    keywords: [],
    providerSite: {
      loading: false,
      active: false,
      owner: false,
      provider: null,
    },
    similarProviders: {
      slug: null,
      providers: [],
    },
    users: [],
  },
  getters: {
    categories(state) {
      let categories = state.serviceTypes
        .map((p) => p.group)
        .filter((value, index, self) => self.indexOf(value) === index); // distinct;
      categories = categories.map((p) => {
        const group = {
          group: p,
          group_name: state.serviceTypes.find((g) => g.group === p).group_name,
          backgroundImage: `https://eventeros.b-cdn.net/static/images/services/${p}/${p}.png`,
          services: state.serviceTypes
            .map((a) => ({ ...a }))
            .filter((s) => s.group === p).map((s) => {
              const service = {
                ...s,
                ...{ selected: false },
              };
              return service;
            })
            .sort((a, b) => a.type_name.localeCompare(b.type_name)),
          selected: false,
        };
        return group;
      });
      return categories
        .sort((a, b) => a.group_name.localeCompare(b.group_name));
    },
  },
  mutations: {
    [types.EVENT_TYPES](state, eventTypes) {
      state.eventTypes = [...eventTypes];
    },
    [types.EVENT_TYPES_TOP](state, eventTypesTop) {
      state.eventTypesTop = [...eventTypesTop];
    },
    [types.SERVICE_TYPES](state, serviceTypes) {
      state.serviceTypes = [...serviceTypes];
    },
    [types.LOCATIONS](state, locations) {
      state.locations = [...locations];
    },
    [types.SELECTED_SERVICEGROUPS](state, selectedServiceGroups) {
      state.selectedServiceGroups = [...selectedServiceGroups];
    },
    [types.SELECTED_SERVICETYPES](state, selectedServiceTypes) {
      state.selectedServiceTypes = [...selectedServiceTypes];
    },
    [types.SELECTED_EVENTTYPES](state, selectedEventTypes) {
      state.selectedEventTypes = [...selectedEventTypes];
    },
    [types.SELECTED_LOCATIONS](state, selectedLocations) {
      state.selectedLocations = [...selectedLocations];
    },
    [types.QUOTATION](state, quotation) {
      state.quotation = { ...quotation };
    },
    [types.NEW_PROVIDERS](state, newProviders) {
      state.newProviders = [...newProviders];
    },
    [types.FEATURED_PROVIDERS](state, featuredProviders) {
      state.featuredProviders = [...featuredProviders];
    },
    [types.COUNT_PROVIDERS](state, countProviders) {
      state.countProviders = countProviders;
    },
    [types.KEYWORDS](state, keywords) {
      state.keywords = [...keywords];
    },
    [types.PROVIDER_SITE](state, providerSite) {
      state.providerSite = providerSite;
    },
    [types.SIMILAR_PROVIDERS](state, { slug, providers }) {
      const similarProviders = providers
        .filter((p) => p.slug !== slug)
        .slice(0, 5)
        .map((p) => {
          const provider = {
            id: p.id,
            company_name: p.company_name,
            slug: p.slug,
            logo_id: p.logo_id,
            province: p.province,
            district: p.district,
            reviews_score: p.reviews_score,
            reviews_number: p.reviews_number,
            cover: p.cover,
            cover_photos: p.cover_photos,
          };
          return provider;
        });
      state.similarProviders = {
        ...state.similarProviders,
        ...{
          slug,
          providers: similarProviders,
        },
      };
    },
    [types.USERS](state, users) {
      state.users = [...users];
    },
  },
  actions: {
    async refreshEventTypes({ commit, state }, obj) {
      refreshIfEmpty({ commit, state }, types.EVENT_TYPES, eventTypeController.search, obj);
    },
    async refreshEventTypesTop({ commit, state }, obj) {
      refreshIfEmpty({ commit, state }, types.EVENT_TYPES_TOP, eventTypeController.popular, obj);
    },
    async refreshServiceTypes({ commit, state }, obj) {
      refreshIfEmpty({ commit, state }, types.SERVICE_TYPES, serviceTypeController.search, obj);
    },
    async refreshLocations({ commit, state }, obj) {
      refreshIfEmpty({ commit, state }, types.LOCATIONS, locationController.search, obj);
    },
    refreshSelectedServiceGroups({ commit }, obj) {
      commit(types.SELECTED_SERVICEGROUPS, obj);
    },
    refreshSelectedServiceTypes({ commit }, obj) {
      commit(types.SELECTED_SERVICETYPES, obj);
    },
    refreshSelectedEventTypes({ commit }, obj) {
      commit(types.SELECTED_EVENTTYPES, obj);
    },
    refreshSelectedLocations({ commit }, obj) {
      commit(types.SELECTED_LOCATIONS, obj);
    },
    saveQuotation({ commit }, quotation) {
      commit(types.QUOTATION, quotation);
    },
    clearQuotation({ commit }) {
      commit(types.QUOTATION, null);
    },
    async refreshNewProviders({ commit, state }) {
      refreshIfEmpty({ commit, state }, types.NEW_PROVIDERS, providerController.searchLatest);
    },
    async refreshFeaturedProviders({ commit, state }) {
      // eslint-disable-next-line max-len
      refreshIfEmpty({ commit, state }, types.FEATURED_PROVIDERS, providerController.searchFeatured);
    },
    async refreshCountProviders({ commit, state }) {
      refreshIfEmpty({ commit, state }, types.COUNT_PROVIDERS, providerController.counter);
    },
    async refreshKeywords({ commit, state }) {
      refreshIfEmpty({ commit, state }, types.KEYWORDS, searchController.keywords);
    },
    async addKeyword({ commit, state }, query) {
      if (query && query.length > 3) {
        const keywords = state.keywords.map((p) => ({ ...p }));
        let keyword = keywords.find((p) => p.entry_type === 'query' && p.description === query);
        if (!keyword) {
          keyword = {
            id: 0,
            description: query.toLowerCase(),
            entry_type: 'query',
            priority: 7,
            custom1: null,
            custom2: null,
            reference_id: null,
            reference_key: null,
          };
          keywords.push(keyword);
          commit(types.KEYWORDS, keywords);
        }
      }
    },
    async refreshProviderSite({ commit, state }) {
      let loading = true;
      // change state to loading
      let providerSite = {
        ...state.providerSite,
        ...{ loading, provider: null },
      };
      commit(types.PROVIDER_SITE, providerSite);
      // pull provider info
      const route = VueRouter.currentRoute;
      const user = Vue.prototype.$auth.user();
      const active = route.name === 'providersite';
      let provider = null;
      let owner = false;
      if (active) {
        const slug = route.path.replace('/', '');
        window.prerenderReady = false;
        provider = await providerController.searchBySlug(
          slug,
          false, // withLogo
          '*', // columns
          'servicesTypes,location,settings,likes,reviews,reviews.detail,reviews.user,album,album.media,album.media.services,covers',
        );
        if (provider) {
          if (user) {
            owner = provider.created_by === user.id;
            // link user and provider if is owner
            if (owner && !user.provider) {
              user.provider = provider;
            }
          }
          await Vue.prototype.$waitFor(500);

          // state for similar providers
          let { similarProviders } = state;
          if (similarProviders.slug && similarProviders.slug === provider.slug) {
            similarProviders = [...similarProviders.providers];
          } else {
            similarProviders = [];
          }

          provider = { ...provider, ...{ similarProviders } };
          window.setTimeout(() => {
            window.prerenderReady = true;
          }, 200);
        }
      }
      loading = false;
      // update providerSite
      providerSite = {
        loading,
        active,
        owner,
        provider,
      };
      commit(types.PROVIDER_SITE, providerSite);
    },
    async updateProviderSiteInfo({ commit, state }, provider) {
      const providerSite = { ...state.providerSite };
      providerSite.provider = { ...providerSite.provider, ...provider };
      commit(types.PROVIDER_SITE, providerSite);
    },
    async setSimilarProviders({ commit }, { slug, providers }) {
      await commit(types.SIMILAR_PROVIDERS, { slug, providers });
    },
    clearSimilarProviders({ commit }) {
      commit(types.SIMILAR_PROVIDERS, null, []);
    },
    async refreshUsers({ commit, state }, obj) {
      refreshIfEmpty({ commit, state }, types.USERS, userController.search, obj);
    },
  },
};
