import axios from 'axios';

export default {

  async search() {
    return axios.get('/api/v1/event-types');
  },

  async popular() {
    return axios.get('/api/v1/event-types/popular');
  },

};
