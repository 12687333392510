<template>
  <v-dialog
    v-model="dialog"
    :width="options.width"
    :style="{ zIndex: options.zIndex }"
    @keydown.esc="cancel"
    content-class="confirm-dialog">
    <v-card
      class="pa-3 text-center rounded-lg">
      <img
        v-if="options.icon"
        width="100px"
        class="mt-4"
        :src="options.icon"
        alt="Confirmar" />
      <v-card-title
        class="justify-center font-weight-bold pb-0">
        {{ title }}
        <v-btn
          absolute
          small
          text
          fab
          top
          right
          color="grey"
          style="top: 10px; right: 10px"
          @click.native="cancel"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text
        v-show="!!message"
        class="black--text pa-5"
        :class="options.message.class"
      >
        <span v-html="message"></span>
      </v-card-text>
      <v-card-actions
        class="py-4">
        <v-btn
          v-if="!options.noconfirm"
          color="grey"
          text
          class="action flex-grow-1"
          @click.native="cancel">
          Cancelar
        </v-btn>
        <v-btn
          dark
          :color="options.confirm.color"
          class="action confirm flex-grow-1"
          @click.native="agree">
          {{ options.confirm.text }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ConfirmDialog',
  props: {
    customOptions: null,
  },
  data() {
    return {
      dialog: false,
      resolve: null,
      reject: null,
      message: null,
      title: null,
      options: {
        color: 'grey lighten-4',
        width: 320,
        zIndex: 200,
        noconfirm: false,
        icon: null,
        message: {
          class: '',
        },
        confirm: {
          color: 'primary',
          text: 'Confirmar',
        },
      },
    };
  },
  created() {
    this.options = { ...this.options, ...this.customOptions };
  },
  methods: {
    open(title, message, options) {
      this.dialog = true;
      this.title = title;
      this.message = message;
      this.options = Object.assign(this.options, options);
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    agree() {
      this.resolve(true);
      this.dialog = false;
    },
    cancel() {
      this.resolve(false);
      this.dialog = false;
    },
  },
};
</script>

<style lang="scss">
.confirm-dialog {
  .v-card__subtitle, .v-card__text {
    line-height: 1.5rem!important;
  }
}
</style>
