<template>
  <div
    class="category-chip-wrapper"
    :class="[{'full-width': fullWidth}]">
    <div
      class="d-flex align-center category-chip category"
      :class="[
        `${category.group}-background`,
        {'no-multicolor': !multiColor},
        {'full-width': fullWidth},
        'lighten-02',
      ]">
      <div
        class="description">
        <v-icon
          size="14"
          :class="[
            `category ${category.group}-color`,
            {'no-multicolor': !multiColor},
          ]">
          fa-services-{{ category.group }}
        </v-icon>
        <span>
          {{ category.group_name }}
        </span>
      </div>
      <v-spacer></v-spacer>
      <div
        v-if="category.count && showCounter"
        class="white--text rounded service-count ml-3"
        :class="`category ${category.group}-background`">
        <span>{{ category.count }}</span>
      </div>
    </div>
    <div
      v-if="showServices"
      class="services-chips grey--text text--darken-2">
      {{ category.services.map((p) => p.type_name).join(' • ') }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'CategoryChip',
  props: {
    category: {
      type: Object,
      default: null,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    showCounter: {
      type: Boolean,
      default: false,
    },
    showServices: {
      type: Boolean,
      default: false,
    },
    multiColor: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.category-chip-wrapper {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  gap: 6px;

  &.full-width {
    width: 100%;
    border-radius: 6px;
    margin-bottom: 6px;
  }

  @media screen and (max-width: 960px) {
    flex-direction: column;
    align-items: flex-start;
  }

  .services-chips {
    font-size: 0.85rem;
  }
}

.category-chip {
  font-family: 'Work Sans', sans-serif;
  font-size: 0.85rem;
  padding: 4px 18px 4px 12px;
  border-radius: 20px;

  &.full-width {
    width: 100%;
    border-radius: 6px;
    margin-bottom: 6px;
  }

  $category-chip-background: rgba(244, 238, 254, 1);
  $category-chip-color: rgba(77, 47, 187, 1);
  &.no-multicolor {
    background-color: $category-chip-background!important;
    color: $category-chip-color!important;

    .v-icon::before {
      background-color: $category-chip-color!important;
    }
  }

  .description {
    flex-shrink: 0;
  }

  .service-count {
    width: 18px;
    height: 18px;
    text-align: center;
    font-size: 12px;
    font-weight: 500;
  }

}
</style>
