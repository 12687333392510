<template>
  <div
    class="media-item-wrapper"
    :style="wrapperProps">
    <v-hover
      v-slot="{ hover }"
      v-if="imageSrc">
      <v-card
        flat
        tile
        class="media-item"
        :class="{'clickable': clickable}"
        :style="imageProps"
        @click.stop.prevent="if (clickable) $emit('clicked', index)">
        <v-img
          v-if="index >= 0"
          :src="imageSrc"
          :lazy-src="imageSrc"
          aspect-ratio="1"
          class="grey lighten-2"
          :style="imageProps">
          <template #placeholder>
            <v-row
              class="fill-height ma-0"
              align="center"
              justify="center">
              <v-progress-circular
                indeterminate
                color="grey lighten-5"
              ></v-progress-circular>
            </v-row>
          </template>
        </v-img>
        <!-- overlay clearable -->
        <v-fade-transition>
          <v-overlay
            v-if="clearable && hover && !uploading"
            class="media-clearable-overlay"
            opacity="0.1"
            absolute>
            <v-btn
              icon
              class="media-clearable-icon"
              @click.stop.prevent="$emit('cleared', index)">
              <v-icon
                small>
                mdi-close
              </v-icon>
            </v-btn>
          </v-overlay>
        </v-fade-transition>
        <!-- overlay uploading -->
        <v-overlay
          v-if="uploading"
          color="grey lighten-3"
          absolute>
          <v-card
            flat
            width="30">
            <v-progress-linear
              background-color="blue lighten-5"
              color="brand-blue"
              indeterminate
              rounded
              height="8"
            ></v-progress-linear>
          </v-card>
        </v-overlay>
        <!-- overlay uploaded -->
        <v-overlay
          v-if="!uploading && uploaded"
          color="grey darken-3"
          absolute>
          <v-icon
            class="media-uploaded-icon">
            mdi-check-bold
          </v-icon>
        </v-overlay>
        <!-- overlay sort order -->
        <v-fade-transition>
          <v-overlay
            v-if="sortable && sortOrder > 0 && hover"
            class="rounded-lg"
            absolute
            :sortable="true">
            {{ sortOrder }}
          </v-overlay>
        </v-fade-transition>
        <!-- overlay selected -->
        <v-overlay
          v-if="selectable && selected"
          class="media-selected-overlay"
          opacity="0.1"
          absolute>
          <v-btn
            icon
            class="media-selected-icon"
            @click.stop.prevent="$emit('unselected', index)">
            <v-icon>
              mdi-check-circle
            </v-icon>
          </v-btn>
        </v-overlay>
      </v-card>
    </v-hover>
    <v-card
      v-else
      flat
      class="media-item-shadow"
      :style="shadowProps">
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'PhotosSelectorItem',
  props: {
    index: {
      default: -1,
    },
    dataUrl: {
      default: null,
    },
    thumbnail: {
      default: null,
    },
    uploading: {
      default: false,
    },
    uploaded: {
      default: false,
    },
    selectable: {
      default: false,
    },
    selected: {
      default: false,
    },
    sortable: {
      default: false,
    },
    sortOrder: {
      default: 0,
    },
    clearable: {
      default: false,
    },
    clickable: {
      default: false,
    },
    customStyle: {
      default: null,
    },
  },
  computed: {
    wrapperProps() {
      const style = { ...this.defaultStyle, ...this.customStyle };
      return `max-width: ${style.width};
        flex-basis: ${style.width};
      `;
    },
    imageProps() {
      const style = { ...this.defaultStyle, ...this.customStyle };
      return `border-radius: ${style.borderRadius}!important;`;
    },
    shadowProps() {
      const style = { ...this.defaultStyle, ...this.customStyle };
      return `border-radius: ${style.borderRadius}!important;
        width: ${style.width}!important;
        height: ${style.height}!important;
        background-color: #ECECEC!important;
      `;
    },
    imageSrc() {
      return this.dataUrl ?? this.thumbnail;
    },
  },
  data() {
    return {
      defaultStyle: {
        width: '50px',
        height: '50px',
        borderRadius: '6px',
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.media-item-wrapper {
  aspect-ratio: 1;

  .media-item {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: #D1D1D1;
  }

  .media-selected-overlay {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    padding: 2px;
  }

  .media-selected-icon {
    position: absolute;
    width: 24px;
    min-width: 24px!important;
    height: 24px;
    bottom: -8px;
    right: -8px;
    background: #F5F5F5;
    border-radius: 20px;
    color: $brand-blue-darken-1!important;
  }

  .media-clearable-overlay {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    padding: 2px;
  }

  .media-clearable-icon {
    position: absolute;
    width: 18px;
    min-width: 18px!important;
    height: 18px;
    bottom: -8px;
    right: -8px;
    background: #F5F5F5;
    border-radius: 20px;
    color: black!important;
  }

  .media-uploaded-icon {
    color: white;
  }
}
</style>
