<template>
  <e-select
    :filled="filled"
    :name="name"
    :rules="rules"
    :mode="mode"
    :items="orderedEventTypes"
    item-text="type_name"
    item-value="id"
    v-model="innerValue"
    v-on="$listeners">
    <div slot="label">
      <i v-if="icon" :class="`${icon} subheading`"></i>
      {{label}}
    </div>
    <!-- pass named slots -->
    <template
      v-for="(slot, name) in $slots"
      :slot="name">
      <slot v-if="name === 'default'"></slot>
      <slot v-if="name !== 'default'" :name="name"></slot>
    </template>
    <!-- pass scoped slots -->
    <template
      v-for="(slot, name) in $scopedSlots"
      :slot="name"
      slot-scope="props">
      <slot :name="name" v-bind="props"></slot>
    </template>
    <!-- item slot -->
    <template
      slot="item"
      slot-scope="{ item }">
      <div
        v-if="item.type === 'header'"
        class="grey--text">
        {{ item['group_name'] }}
      </div>
      <div
        v-else
        :class="{'pl-4': grouped}">
        {{ item["type_name"] }}
      </div>
    </template>
  </e-select>
</template>

<script>

import { mapActions, mapState } from 'vuex';

export default {
  name: 'SelectEventTypes',
  inheritAttrs: false,
  props: {
    label: {
      type: String,
      required: false,
      default: 'Seleccionar evento',
    },
    icon: {
      type: [String, Boolean],
      required: false,
      default: 'fa fa-events',
    },
    name: {
      type: String,
      required: true,
      default: null,
    },
    value: {
      type: [Number, String, Array, Object],
      required: false,
      default: null,
    },
    rules: [String],
    mode: {
      type: String,
      default: 'eager',
    },
    filled: {
      type: Boolean,
      default: true,
    },
    grouped: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      innerValue: this.value,
    };
  },
  created() {
    this.refreshEventTypes();
  },
  computed: {
    ...mapState('eventeros/', ['eventTypes']),
    orderedEventTypes() {
      let eventTypes = [...this.eventTypes];
      if (!this.grouped) {
        // ungrouped
        eventTypes = eventTypes
          .sort((a, b) => a.type_name.localeCompare(b.type_name));
      } else {
        // grouped
        let groups = eventTypes
          .sort((a, b) => a.group_order - b.group_order)
          .map((p) => p.group_name)
          .filter((value, index, self) => self.indexOf(value) === index); // distinct
        groups = groups
          .map((groupName) => {
            const group = {
              type: 'header',
              group_name: groupName,
              disabled: true,
            };
            return group;
          });
        const customItems = [];
        groups.forEach((group) => {
          const filtered = eventTypes
            .filter((p) => p.group_name === group.group_name)
            .sort((a, b) => a.type_name.localeCompare(b.type_name));
          customItems.push(group);
          customItems.push(...filtered);
        });
        eventTypes = customItems;
      }
      return eventTypes;
    },
  },
  methods: {
    ...mapActions('eventeros/', ['refreshEventTypes']),
  },
};
</script>
