/* eslint no-underscore-dangle: ["error", { "allow": ["_field_", "_rule_"] }] */

// https://vee-validate.logaretm.com/v3/overview.html
// https://vee-validate.logaretm.com/v3/guide/localization.html

// Using built-in i18n from vee-validate

import Vue from 'vue';
import {
  ValidationObserver,
  ValidationProvider,
  extend,
  localize,
} from 'vee-validate';
import en from 'vee-validate/dist/locale/en.json';
import es from 'vee-validate/dist/locale/es.json';
import names from './names';
import fields from './fields';
import rules from './rules';

// add generic full messages
localize({
  es,
  en,
});

// add names of fields{
localize({
  es: {
    names,
  },
});

// add custom messages per field
localize({
  es: {
    fields,
  },
});

// set locale spanish (es)
localize('es');

// add rules
Object.keys(rules).forEach((rule) => {
  //  add the rule.
  extend(rule, rules[rule]);
});

// register it globally
Vue.component('ValidationObserver', ValidationObserver);
Vue.component('ValidationProvider', ValidationProvider);
