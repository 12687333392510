<template>
  <div :style="`
    width: ${width};
    max-width: ${maxWidth}
  `">
    <e-text-field
      type="search"
      :name="name"
      :rules="rules"
      :mode="mode"
      :label="label"
      :filled="true"
      :solo="true"
      :flat="true"
      :full-width="fullWidth"
      :autofocus="autofocus"
      :hide-details="true"
      @keypress="$emit('keypress', $event)"
      v-model="innerValue"
      prepend-inner-icon="mdi-magnify"
      custom-class="search-input filled-white"
    ></e-text-field>
  </div>
</template>

<script>

export default {
  name: 'SearchInput',
  inheritAttrs: false,
  props: {
    name: null,
    value: null,
    rules: [String],
    mode: {
      type: String,
      default: 'eager',
    },
    label: {
      type: String,
      default: 'buscar',
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: '100%',
    },
    maxWidth: {
      type: String,
      default: '100%',
    },
  },
  data() {
    return {
      innerValue: this.value,
    };
  },
  watch: {
    value(val) {
      this.innerValue = val;
    },
    innerValue(val) {
      this.$emit('input', val);
    },
  },
};
</script>

<style lang="scss">
.search-input {
  display: flex;
  gap: 8px;

  &.filled-white {
    &.theme--light.v-text-field--filled > .v-input__control > .v-input__slot {
      background: white!important;
    }

    &.v-text-field.v-text-field--solo .v-input__control,
    &.v-text-field--filled > .v-input__control > .v-input__slot {
      border-radius: 80px;
      min-height: 40px;
    }
  }
}
</style>
