<template>
  <v-fade-transition>
    <v-overlay
      v-if="loading"
      :color="backColor"
      absolute
    >
      <v-progress-circular
        :size="size"
        :color="color"
        indeterminate
      />
    </v-overlay>
  </v-fade-transition>
</template>

<script>
export default {
  name: 'Loader',
  props: {
    loading: {
      default: false,
    },
    size: {
      default: 50,
    },
    backColor: {
      default: 'white',
    },
    color: {
      default: 'amber',
    },
  },
};
</script>
