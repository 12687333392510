export default {
  quotation: {
    status: {
      delivered: 'enviada',
      opened: 'leída',
      liked: 'en evaluación',
      accepted: 'aceptada',
      discarded: 'descartada',
      upgraded: 'actualizada',
      archived: 'archivada',
    },
  },
};
