<template>
  <e-date-picker
    format-string="dddd, LL"
    :name="name"
    :rules="rules"
    :mode="mode"
    v-on="$listeners"
    v-model="innerValue"
    :min="minEventDate"
  >
    <div slot="label">
      <i v-if="icon" :class="`${icon} subheading`"></i>
      {{label}}
    </div>
  </e-date-picker>
</template>

<script>
export default {
  name: 'EventDatePicker',
  inheritAttrs: false,
  props: {
    name: null,
    value: null,
    rules: [String],
    mode: {
      type: String,
      default: 'eager',
    },
    label: {
      type: String,
      required: false,
      default: 'Fecha del evento',
    },
    icon: {
      type: [String, Boolean],
      required: false,
      default: 'fa fa-availability',
    },
  },
  data() {
    return {
      menu: false,
      innerValue: this.value,
    };
  },
  computed: {
    minEventDate() {
      const now = new Date();
      now.setDate(now.getDate() + 1);
      let month = (now.getMonth() + 1).toString();
      let day = (now.getDate()).toString();
      const year = (now.getFullYear()).toString();

      if (month.length < 2) month = `0${month}`;
      if (day.length < 2) day = `0${day}`;

      return [year, month, day].join('-');
    },
  },
};
</script>
