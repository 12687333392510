<template>
  <div
    class="e-icon-wrapper"
    :class="{'inline': inline}">
    <v-icon
      v-if="!iconify"
      :style="`font-size: ${size}; color: ${color};`">
      {{ iconName }}
    </v-icon>
    <iconify-icon
      v-else
      :style="`font-size: ${size}; color: ${color};`"
      :icon="iconName">
    </iconify-icon>
  </div>
</template>

<script>
export default {
  name: 'Icon',
  props: {
    color: {
      type: String,
      default: 'black',
    },
    icon: {
      type: String,
      default: null,
    },
    size: {
      type: String,
      default: '1rem',
    },
    inline: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    iconify() {
      return this.icon.startsWith('iconify:');
    },
    iconName() {
      return this.icon.replace('iconify:', '');
    },
  },
};
</script>

<style lang="scss" scoped>
.e-icon-wrapper {
  display: flex;
  align-items: center;
  justify-items: center;

  .inline {
    display: inline;
  }
}
</style>
