import axios from 'axios';

export default {

  async searchMedia(albumId) {
    const response = await axios.get(`/api/v1/albums/${albumId}`);
    return response.data;
  },

  async addMedia(albumId, media) {
    return axios.post(`/api/v1/albums/${albumId}/media`, {
      external_id: media.external_id,
      path: media.path,
      mime_type: media.mime_type,
      filename: media.filename,
      seo_filename: media.seo_filename,
      description: media.description,
      width: media.width,
      height: media.height,
      size: media.size,
      album_id: albumId,
    });
  },

  async addVideo(albumId, url, title, description, externalId) {
    return this.addMedia(albumId, {
      id: null,
      data_url: null,
      filename: url,
      seo_filename: title,
      description: description.substring(0, 500), // max length = 500
      mime_type: 'youtube',
      original_filename: url,
      external_id: externalId,
      is_uploading: true,
      selected: false,
      album_id: albumId,
      media_type: 'video',
      sort_order: 0,
      path: null,
      thumbnail: null,
      sizes: null,
      width: 0,
      height: 0,
      size: 0,
    });
  },

  async updateMedia(albumId, mediaId, seoFilename, description, services) {
    return axios.put(`/api/v1/albums/${albumId}/media/${mediaId}`, {
      media_id: mediaId,
      seo_filename: seoFilename,
      description,
      services,
    });
  },

  async deleteMedia(albumId, mediaId, purge = false) {
    return axios.delete(`/api/v1/albums/${albumId}/media/${mediaId}?purge=${purge}`);
  },

  async searchTrash(albumId) {
    const response = await axios.get(`/api/v1/albums/${albumId}/trash`);
    return response.data;
  },

  async purgeMedia(albumId, mediaId) {
    return axios.delete(`/api/v1/albums/${albumId}/media/${mediaId}/purge`);
  },

  async restoreMedia(albumId, mediaId) {
    return axios.put(`/api/v1/albums/${albumId}/media/${mediaId}/restore`);
  },

  async emptyTrash(albumId) {
    return axios.post(`/api/v1/albums/${albumId}/emptytrash`);
  },
};
