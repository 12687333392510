export default {
  app: {
    provider: {
      modules: {
        profile: 'perfil',
        services: 'servicios',
        quotations: 'cotizaciones',
      },
      suscription_plan: {
        basic: 'Básico',
        business: 'Empresarial',
        options: {
          website: 'Sitio web Eventeros',
          covers: 'Fotos de portada',
          quotations: 'Cotizaciones',
          notifications: 'Notificaciones de eventos',
          gallery: 'Galería de fotos y videos',
          testimonials: 'Testimonios',
        },
      },
    },
  },
};
