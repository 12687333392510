<template>
  <ValidationObserver
    ref="observer"
    v-slot="{ handleSubmit }"
    class="wizard-step">
    <form
      ref="form"
      @submit.prevent="handleSubmit(submit)">
      <div
        v-if="!saved"
        class="step-number">
        Paso {{ step }} de {{ stepsNumber }}
      </div>
      <div
        v-if="!saved"
        class="step-title"
        :class="{'text-center': centered}"
        :style="`max-width: ${titleWidth}`">
        {{ title }}
      </div>
      <div class="step-wrapper nice-scrollbar">
        <div
          v-if="!saved"
          class="step-description"
          :class="{'text-center': centered}"
          :style="`max-width: ${titleWidth}`">
          {{ description }}
        </div>
        <div
          class="step-content"
          :style="`max-width: ${contentWidth}`">
          <slot></slot>
        </div>
      </div>
      <div
        v-if="!saved"
        class="actions"
        :style="`max-width: ${contentWidth}`">
        <v-btn
          depressed
          left
          @click="cancel"
          class="px-4"
          :disabled="saving || saved">
          Cancelar
        </v-btn>
        <v-spacer />
        <v-btn
          depressed
          right
          @click="previous"
          class="px-4 mr-3"
          v-if="step > 1"
          :disabled="saving || saved">
          Atrás
        </v-btn>
        <v-btn
          type="submit"
          right
          class="brand-blue px-4"
          :class="lastStep ? 'submit-step' : 'next-step'"
          :disabled="(lastStep && !completed)|| saved"
          :loading="saving">
          {{ lastStep ? confirmLabel : 'Siguiente' }}
        </v-btn>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
export default {
  name: 'WizardStep',
  props: {
    title: {
      type: String,
      default: null,
    },
    description: {
      type: String,
      default: null,
    },
    centered: {
      type: Boolean,
      default: true,
    },
    titleWidth: {
      type: String,
      default: '100%',
    },
    contentWidth: {
      type: String,
      default: '100%',
    },
    step: {
      type: Number,
      default: 1,
    },
    stepsNumber: {
      type: Number,
      default: 1,
    },
    lastStep: {
      type: Boolean,
      default: false,
    },
    confirmLabel: {
      type: String,
      default: 'Confirmar',
    },
    saving: {
      type: Boolean,
      default: false,
    },
    saved: {
      type: Boolean,
      default: false,
    },
    completed: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    cancel() {
      this.$emit('cancel');
    },
    previous() {
      this.$emit('previous');
    },
    submit() {
      this.$emit('submit');
    },
  },
};
</script>

<style lang="scss">
.wizard-step {
  .step-content>div {
    flex: 1;
  }
}

</style>

<style lang="scss" scoped>
.wizard-step {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;

  form {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
  }

  .step-number {
    font-size: 12px;
  }

  .step-title {
    font-family: 'Work Sans', sans-serif;
    font-weight: 700;
    font-size: 1.75rem;
  }

  .step-wrapper {
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: scroll;
    height: 100%;

    .step-description {
      font-size: 1rem;
      margin-bottom: 12px;
    }

    .step-content {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
    }
  }

  .actions {
    display: flex;
    width: 100%;
    padding: 12px 0 0;

    .next-step {
      color: white;
      background-color: $brand-blue!important;
      border-color: $brand-blue!important;
    }

    .submit-step {
      color: white;
      background-color: $brand-green!important;
      border-color: $brand-green!important;
    }
  }
}
</style>
