import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    // route level code-splitting
    // this generates a separate chunk (home.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue'),
  },
  // this route enables a page to scrape all provider urls
  {
    path: '/scraping',
    name: 'scraping',
    meta: { auth: true },
    component: () => import(/* webpackChunkName: "scraping" */ '../views/Scraping.vue'),
  },
  {
    path: '/sobre-nosotros',
    name: 'aboutus',
    component: () => import(/* webpackChunkName: "aboutus" */ '../views/About.vue'),
  },
  {
    path: '/terminos-y-condiciones',
    name: 'termsandconditions',
    component: () => import(/* webpackChunkName: "termsandconditions" */ '../views/TermsAndConditions.vue'),
  },
  {
    path: '/politica-de-privacidad',
    name: 'privacypolicy',
    component: () => import(/* webpackChunkName: "privacypolicy" */ '../views/PrivacyPolicy.vue'),
  },
  {
    path: '/preguntas-frecuentes',
    name: 'faq',
    component: () => import(/* webpackChunkName: "faq" */ '../views/FAQ.vue'),
  },
  {
    path: '/blog',
    name: 'blog',
    component: () => import(/* webpackChunkName: "blogpage" */ '../views/Blog.vue'),
  },
  {
    path: '/blog/:slug',
    name: 'blogpost',
    props: true,
    component: () => import(/* webpackChunkName: "blogpost" */ '../views/BlogPost.vue'),
  },
  {
    path: '/404',
    name: '404',
    component: () => import(/* webpackChunkName: "page404" */ '../views/Page404.vue'),
  },
  {
    path: '/soy-un-proveedor',
    name: 'joinus',
    component: () => import(/* webpackChunkName: "joinus" */ '../views/JoinUs.vue'),
  },
  // {
  //   path: '/planes',
  //   name: 'suscriptionplans',
  //   component: () => import(/* webpackChunkName: "pricing" */ '../views/Pricing.vue'),
  // },
  // {
  //   path: '/precios',
  //   name: 'pricing',
  //   component: () => import(/* webpackChunkName: "pricing" */ '../views/Pricing.vue'),
  // },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "auth" */ '../views/Auth.vue'),
  },
  {
    path: '/login/:provider/callback',
    name: 'oauth2-callback',
    props: true,
    component: () => import(/* webpackChunkName: "auth_callback" */ '../views/Auth.vue'),
  },
  {
    path: '/micuenta',
    name: 'myaccount',
    meta: { auth: true },
    component: () => import(/* webpackChunkName: "myaccount" */ '../views/MyAccount.vue'),
  },
  {
    path: '/proveedores',
    name: 'providers',
    component: () => import(/* webpackChunkName: "search" */ '../views/SearchPage.vue'),
  },
  {
    path: '/image-compressor',
    name: 'imagecompressor',
    component: () => import(/* webpackChunkName: "imagecompressor" */ '../views/ImageCompressor.vue'),
  },
  {
    path: '/proveedores/afiliacion',
    name: 'affiliation',
    component: () => import(/* webpackChunkName: "affiliation" */ '../views/Affiliation.vue'),
    beforeEnter: (to, from, next) => {
      // if logged user has a provider, redirect to his site
      if (Vue.prototype.$auth.check()) {
        const user = Vue.prototype.$auth.user();
        const { provider } = user;
        if (provider) {
          next(`/${provider.slug}`);
        } else {
          next();
        }
      } else {
        next();
      }
    },
  },
  {
    path: '/afiliacion',
    name: 'affiliation-external',
    component: () => import(/* webpackChunkName: "affiliation-external" */ '../views/Affiliation.vue'),
  },
  {
    path: '/sitioweb',
    name: 'website',
    component: () => import(/* webpackChunkName: "website" */ '../views/Website.vue'),
  },
  {
    path: '/buscar',
    name: 'search',
    component: () => import(/* webpackChunkName: "search" */ '../views/SearchPage.vue'),
  },
  {
    path: '/eventos/crear/:step?',
    name: 'createevent',
    component: () => import(/* webpackChunkName: "createuserevent" */ '../views/CreateUserEvent.vue'),
  },
  {
    path: '/eventos',
    name: 'events',
    redirect: () => {
      const user = Vue.prototype.$auth.user();
      const { provider } = user;
      let redirectPath = 'eventos/lista';
      if (provider) {
        redirectPath = 'eventos/buscador';
      }
      return redirectPath;
    },
  },
  {
    path: '/eventos/buscador',
    name: 'events_searcher',
    component: () => import(/* webpackChunkName: "events" */ '../views/Events.vue'),
  },
  {
    path: '/eventos/buscador/:id',
    name: 'events_searcher_detail',
    component: () => import(/* webpackChunkName: "events" */ '../views/Events.vue'),
  },
  {
    path: '/eventos/lista',
    name: 'events_myevents',
    component: () => import(/* webpackChunkName: "events" */ '../views/Events.vue'),
  },
  {
    path: '/eventos/:id',
    name: 'events_detail',
    component: () => import(/* webpackChunkName: "events" */ '../views/Events.vue'),
  },
  {
    path: '/cotizaciones',
    name: 'quotations',
    component: () => import(/* webpackChunkName: "quotations" */ '../views/Quotations.vue'),
  },
  {
    path: '/:slug',
    name: 'providersite',
    props: true,
    component: () => import(/* webpackChunkName: "providersite" */ '../views/ProviderSite.vue'),
  },
  {
    path: '*',
    redirect: '404',
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: (to, from, savedPosition) => { // eslint-disable-line
    document.getElementById('app').scrollIntoView({ behavior: 'smooth' });
  },
});

Vue.router = router;

export default router;
