/* eslint-disable quote-props */
export default {
  // Categories

  // political events
  'political': 'Eventos políticos',
  'political.debates': 'Debate',
  'political.diplomaticreceptions': 'Recepción diplomática',
  'political.investiture': 'Ceremonia de investidura',
  'political.proselytizing': 'Evento proselitista',
  'political.summits': 'Cumbre',

  // corporate events
  'corporate': 'Eventos corporativos',
  'corporate.anniversaries': 'Aniversario',
  'corporate.businessmeetings': 'Encuentro de negocios',
  'corporate.ceremonies': 'Ceremonia',
  'corporate.congresses': 'Congreso',
  'corporate.conventions': 'Convención',
  'corporate.integrationevents': 'Evento de integración ',
  'corporate.outdoors': 'Outdoor',
  'corporate.seminars': 'Seminario',
  'corporate.summits': 'Cumbre',
  'corporate.trainings': 'Capacitación',
  'corporate.worksessions': 'Sesión de trabajo',
  'corporate.workshops': 'Workshop',

  // marketing events
  'marketing': 'Eventos de marketing',
  'marketing.artexhibitions': 'Muestra de arte',
  'marketing.bookspresentations': 'Presentación de libro',
  'marketing.demonstrations': 'Demostración',
  'marketing.fairs': 'Feria',
  'marketing.fashionshows': 'Desfile de moda',
  'marketing.pressconferences': 'Conferencia de prensa',
  'marketing.productlaunches': 'Lanzamiento  de producto',
  'marketing.productshowcase': 'Exhibición de productos',
  'marketing.promotionalevents': 'Evento promocional',
  'marketing.showrooms': 'Showroom',
  'marketing.tradeshows': 'Tradeshow',

  // social events
  'social': 'Eventos sociales',
  'social.15yearsparties': 'Quinceañero',
  'social.artisticshows': 'Espectáculo artístico',
  'social.babyshowers': 'Baby shower',
  'social.bachelorparties': 'Despedida de soltero',
  'social.baptisms': 'Bautizo',
  'social.birthdayparties': 'Fiesta de cumpleaños',
  'social.childrenparties': 'Cumpleaños infantil',
  'social.communityparties': 'Fiesta comunitaria',
  'social.concerts': 'Concierto',
  'social.endofyearparties': 'Fiesta de fin de año',
  'social.familymeetings': 'Reunión familiar',
  'social.farewellparties': 'Fiesta de despedida',
  'social.fellowshipevents': 'Reunión de confraternidad',
  'social.festivals': 'Festival',
  'social.graduatedparties': 'Fiesta de egresados',
  'social.graduations': 'Graduación',
  'social.kermes': 'Kermés',
  'social.marriages': 'Matrimonio',
  'social.openhouse': 'Open House',
  'social.religiousceremonies': 'Ceremonia religiosa',
  'social.reunions': 'Reencuentro',
  'social.sportevents': 'Evento deportivo',
  'social.surpriseparties': 'Fiesta sorpresa',
  'social.themeparties': 'Fiesta temática ',
  'social.weddinganniversaries': 'Aniversario de bodas',
};
