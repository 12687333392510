<template>
  <ValidationProvider
    :mode="mode"
    :name="name"
    :rules="rules"
    v-slot="{ errors }">
    <v-select
      flat
      solo
      outlined
      multiple
      :label="label"
      :items="data"
      :item-text="itemText"
      :item-value="itemValue"
      :hide-details="hideDetails"
      :full-width="fullWidth"
      :readonly="readonly"
      :clearable="clearable"
      return-object
      v-model="innerValue"
      v-on="$listeners"
      :error-messages="errors[0]"
      class="e-multi-select"
    >
      <!-- pass named slots -->
      <template
        v-for="(slot, name) in $slots"
        :slot="name">
          <slot v-if="name === 'default'"></slot>
          <slot v-if="name !== 'default'" :name="name"></slot>
      </template>
      <!-- pass scoped slots -->
      <template
        v-for="(slot, name) in $scopedSlots"
        :slot="name"
        slot-scope="props">
          <slot :name="name" v-bind="props"></slot>
      </template>
      <!-- selection slot -->
      <template
        slot="selection"
        slot-scope="{ item, index }">
        <span
          class="selected-item"
          v-if="index < maxItems">{{ item[itemText] }}</span>
        <span
          v-if="index === maxItems"
          class="more-items grey--text text-caption">
          +{{ innerValue.length - maxItems }}
        </span>
      </template>
    </v-select>
  </ValidationProvider>
</template>

<script>

export default {
  name: 'MultiSelect',
  inheritAttrs: false,
  props: {
    data: {
      type: Array,
      required: true,
      default: () => [],
    },
    itemText: {
      type: String,
      required: true,
      default: null,
    },
    itemValue: {
      type: String,
      required: true,
      default: null,
    },
    label: {
      type: String,
      required: false,
      default: 'Seleccionar',
    },
    name: {
      type: String,
      required: true,
      default: null,
    },
    value: {
      type: [Number, String, Array, Object],
      required: false,
      default: null,
    },
    rules: [String],
    mode: {
      type: String,
      default: 'eager',
    },
    maxItems: {
      type: Number,
      default: 1,
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      innerValue: this.value,
    };
  },
  watch: {
    value(val) {
      this.innerValue = val;
    },
  },
};
</script>

<style lang="scss">

.e-multi-select {
  .v-select__selections {
    .selected-item:not(:first-child) {
      &::before {
        content: ',';
        margin-right: 4px;
      }
    }

    .more-items {
      margin-left: 4px;
    }
  }
}

</style>
