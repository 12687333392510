/* eslint-disable camelcase */
import axios from 'axios';

export default {

  async search() {
    return axios.get('/api/v1/users');
  },

  async update(id, first_name, last_name, gender, birth_date) {
    return axios.put(`/api/v1/user/${id}`, {
      first_name,
      last_name,
      gender,
      birth_date,
    });
  },

  async updateAvatar(id, avatar_preferred) {
    return axios.put(`/api/v1/user/${id}/avatar`, {
      avatar_preferred,
    });
  },

};
