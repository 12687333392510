<template>
  <e-multi-select
    :name="name"
    :rules="rules"
    :data="orderedEventTypes"
    :label="label"
    :max-items="maxItems"
    :hide-details="hideDetails"
    item-text="type_name"
    item-value="id"
    v-model="innerValue"
    v-on="$listeners">
    <!-- Pass on all named slots -->
    <slot
      v-for="slot in Object.keys($slots)"
      :name="slot"
      :slot="slot"
    />
    <!-- Pass on all scoped slots -->
    <template
      v-for="slot in Object.keys($scopedSlots)"
      :slot="slot"
      slot-scope="scope">
      <slot :name="slot" v-bind="scope"/>
    </template>
    <!-- item slot -->
    <template
      slot="item"
      slot-scope="{ item, attrs, on }">
      <v-list-item v-on="on" v-bind="attrs" #default="{ active }">
        <template
          v-if="item.type === 'header'">
          <span
            class="grey--text">
            {{ item['group_name'] }}
          </span>
        </template>
        <template
          v-else>
          <v-list-item-action>
            <v-checkbox :input-value="active"></v-checkbox>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              <v-row no-gutters align="center">
                <span>{{ item["type_name"] }}</span>
              </v-row>
            </v-list-item-title>
          </v-list-item-content>
        </template>
      </v-list-item>
    </template>
  </e-multi-select>
</template>

<script>

import { mapActions, mapState } from 'vuex';

export default {
  name: 'MultiSelectEventTypes',
  inheritAttrs: false,
  props: {
    name: {
      type: String,
      required: false,
      default: 'eventTypes',
    },
    label: {
      type: String,
      required: false,
      default: 'Seleccionar eventos',
    },
    value: null,
    rules: [String],
    maxItems: {
      type: Number,
      required: false,
      default: 1,
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      innerValue: this.value,
    };
  },
  watch: {
    value(val) {
      this.innerValue = val;
    },
  },
  created() {
    this.refreshEventTypes();
  },
  computed: {
    ...mapState('eventeros/', ['eventTypes']),
    orderedEventTypes() {
      const eventTypes = [...this.eventTypes];
      const groups = eventTypes
        .sort((a, b) => a.group_order - b.group_order)
        .map((p) => p.group_name)
        .filter((value, index, self) => self.indexOf(value) === index)
        .map((groupName) => {
          const group = {
            type: 'header',
            group_name: groupName,
            disabled: true,
          };
          return group;
        });
      const customItems = [];
      groups.forEach((group) => {
        customItems.push(group);
        customItems.push(...this.getEventsByGroup(group.group_name));
      });
      return customItems;
    },
  },
  methods: {
    ...mapActions('eventeros/', ['refreshEventTypes']),
    getEventsByGroup(groupName) {
      return this.eventTypes
        .filter((p) => p.group_name === groupName)
        .sort((a, b) => a.type_name.localeCompare(b.type_name)); // sorted by type_name
    },
  },
};
</script>
