<template>
  <ValidationObserver
    ref="observer"
    v-slot="{ handleSubmit, errors }">
    <form @submit.prevent="handleSubmit(submit)">
      <div
        class="modal-form container">
        <div class="header">
          <e-title
            font-size="1.4rem">
            {{title}}
          </e-title>
          <v-btn
            absolute
            small
            text
            fab
            top
            right
            color="grey"
            style="top: 10px; right: 10px"
            :disabled="waiting"
            @click.native="cancel">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <div
          ref="content"
          :class="`content nice-scrollbar ${contentClass}`">
          <div>
            <slot></slot>
          </div>
          <form-error
            :errors="errors"
            @toogleVisible="onErrors">
          </form-error>
        </div>
        <div class="actions">
          <slot name="actions-left"></slot>
          <v-spacer></v-spacer>
          <v-btn
            depressed
            text
            right
            class="grey--text px-8 px-sm-10"
            :disabled="waiting"
            @click.native="cancel">
            Cancelar
          </v-btn>
          <v-btn
            right
            type="submit"
            class="brand-green-darken-1 white--text px-8 px-sm-10"
            :loading="waiting">
            Guardar
          </v-btn>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
export default {
  name: 'ModalForm',
  props: {
    waiting: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: 'Título',
    },
    contentClass: {
      type: String,
      default: '',
    },
  },
  methods: {
    submit() {
      this.$emit('submit');
    },
    cancel() {
      this.$emit('cancel');
    },
    onErrors() {
      const container = this.$refs.content;
      this.$nextTick(() => {
        container.scrollTop = container.scrollHeight;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
form {
  height: 100%;
}

.modal-form {
  position: relative;
  background-color: white;

  &.container {
    display: grid;
    height: 100%;
    grid-template-rows: 40px auto 40px;
    grid-template-columns: 100%;
    grid-gap: 16px;
    padding: 16px;

    &>div {
      padding: 0 16px;
    }

    @media screen and (max-width: 600px) {
      padding: 8px;

      &>div {
        padding: 0 8px;
      }
    }

    .header {
      display: inline-flex;
      align-items: flex-end;
    }

    .content {
      overflow-y: auto;
    }

    .actions {
      display: inline-flex;
      gap: 12px;
    }
  }
}
</style>
