/* eslint-disable camelcase */
import axios from 'axios';

export default {

  async search({ withLogo, columns, relations }) {
    const url = '/api/v1/providers';
    const params = {
      withLogo,
    };
    if (columns) {
      params.columns = columns;
    }
    if (relations) {
      params.relations = relations;
    }
    return axios.get(url, { params });
  },

  async searchByCode(code) {
    const url = 'api/v1/provider';
    const params = {
      code,
    };
    return axios.get(url, { params });
  },

  async searchBySlug(slug, withLogo, columns, relations) {
    let provider = null;
    const url = '/api/v1/providers';

    const params = {
      withLogo,
      slug,
      columns,
    };

    if (relations) {
      params.relations = relations;
    }

    try {
      const response = await axios.get(url, { params });
      const providers = response.data;
      if (providers && providers.length === 1) {
        [provider] = providers;
      }
    } catch (e) {
      provider = null;
    }
    return provider;
  },

  async searchLatest() {
    return axios.get('/api/v1/providers/latest');
  },

  async searchFeatured() {
    return axios.get('/api/v1/providers/featured');
  },

  async get(id, columns) {
    const params = { columns };
    return axios.get(`/api/v1/providers/${id}`, { params });
  },

  async counter() {
    const response = await axios.get('api/v1/providers/count');
    const counter = Math.trunc((response.data) / 10) * 10;
    return counter;
  },

  async register({
    preregister_id,
    guid,
    company_name,
    slug,
    service_type_ids,
    contact_firstname,
    contact_lastname,
    email,
    cellphone,
    address_line1,
    address_line2,
    location_id,
    district_name,
    website,
    created_by,
  }) {
    return axios.post('/api/v1/providers', {
      preregister_id,
      guid,
      company_name,
      slug,
      service_type_ids,
      contact_firstname,
      contact_lastname,
      email,
      cellphone,
      address_line1,
      address_line2,
      location_id,
      district_name,
      website,
      created_by,
    });
  },

  async upgrade(id) {
    return axios.put(`/api/v1/providers/${id}/upgrade`);
  },

  async updateLogo(id, logo_id) {
    return axios.put(`/api/v1/providers/${id}/logo`, {
      logo_id,
    });
  },

  async updateName(id, company_name, slug) {
    return axios.put(`/api/v1/providers/${id}/name`, {
      company_name,
      slug,
    });
  },

  async updateIntro(id, introductory_text) {
    return axios.put(`/api/v1/providers/${id}/intro`, {
      introductory_text,
    });
  },

  async updateServices(id, service_type_ids) {
    return axios.put(`/api/v1/providers/${id}/services`, {
      service_type_ids,
    });
  },

  async updateContact(
    id,
    contact_firstname,
    contact_lastname,
    cellphone,
    email,
    address_line1,
    address_line2,
    location_id,
  ) {
    return axios.put(`/api/v1/providers/${id}/contact`, {
      contact_firstname,
      contact_lastname,
      cellphone,
      email,
      address_line1,
      address_line2,
      location_id,
    });
  },

  async resendCode(email_address) {
    return axios.post('/api/v1/providers/resendCode', {
      email_address,
    });
  },

  async validateCode(code) {
    return axios.post('/api/v1/providers/validateCode', {
      code,
    });
  },

  async quotations(id) {
    return axios.get(`/api/v1/providers/${id}/quotations`);
  },

  async kpis(id) {
    return axios.get(`/api/v1/providers/${id}/kpis`);
  },

  async media(id, media_type, featured, top) {
    const params = {
      media_type,
      featured,
      top,
    };
    const response = await axios.get(`/api/v1/providers/${id}/media`, { params });
    return response.data;
  },

  async delete(id) {
    return axios.delete(`/api/v1/providers/${id}`);
  },

  async unlink(id) {
    return axios.put(`/api/v1/providers/${id}/unlink`);
  },
};
