<template>
  <div
    class="label-icon"
    :class="contentClass"
    :style="`
      gap: ${gap};
      font-size: ${fontSize};
      font-weight: ${fontWeight};
      font-family: ${fontFamily};
    `">
    <e-icon
      v-if="iconName"
      :size="iconSize"
      :color="iconColor ? iconColor : color"
      :icon="iconName">
    </e-icon>
    <span
      :class="[{'paragraph-ellipsis': truncated}]"
      :style="`--lines: ${lines}; color: ${color}; --word-break: ${wordBreak}`">
      {{ text }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'Label',
  props: {
    text: {
      type: [String, Number],
      default: '',
    },
    fontFamily: {
      type: String,
      default: 'inherit',
    },
    fontSize: {
      type: String,
      default: '1rem',
    },
    fontWeight: {
      type: String,
      default: '400',
    },
    color: {
      type: String,
      default: 'black',
    },
    iconName: {
      type: String,
      default: null,
    },
    iconColor: {
      type: String,
      default: null,
    },
    iconSize: {
      type: String,
      default: '1rem',
    },
    contentClass: {
      type: String,
      default: '',
    },
    truncated: {
      type: Boolean,
      default: false,
    },
    lines: {
      type: Number,
      default: 1,
    },
    wordBreak: {
      type: String,
      default: 'break-word',
    },
    gap: {
      type: String,
      default: '2px',
    },
  },
};
</script>

<style lang="scss" scoped>
.label-icon {
  font-family: inherit;
  font-size: inherit;
  display: flex;
  align-items: center;

  .v-icon::before {
    margin-left: 0;
  }
}
</style>
