<template>
  <vue-popper
    :disabled="!show"
    :force-show="show"
    :options="options"
    trigger="clickToOpen"
  >
    <div
      class="popper"
      v-click-outside="close">
      <slot></slot>
    </div>
    <v-overlay
      z-index="10"
      opacity="0.75"
    />
    <slot
      name="reference"
      slot="reference">
    </slot>
  </vue-popper>
</template>

<script>

import VuePopper from 'vue-popperjs';
import 'vue-popperjs/dist/vue-popper.css';

export default {
  name: 'Popper',
  props: {
    show: {
      default: false,
    },
    options: {
      default: {
        placement: 'bottom-end',
        modifiers: { offset: { offset: '0,10px' } },
      },
    },
  },
  methods: {
    close() {
      this.$emit('click-outside');
    },
  },
  components: {
    VuePopper,
  },
};
</script>
