<template>
  <v-dialog
    content-class="video-loader-dialog ma-1"
    width="700px"
    v-model="dialog">
    <v-card flat>
      <v-card-title
        class="pa-1 pl-2 red white--text text-body-1">
        <v-icon
          class="mr-1 white--text">
          mdi-youtube
        </v-icon>
        <span style="padding-top: 1px">
          Insertar video Youtube
        </span>
        <v-spacer />
        <v-btn
          text
          x-small
          fab
          color="white"
          @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider class="ma-0" />
      <v-card-text
        class="d-flex align-center pa-3 pb-0">
        <v-text-field
          solo
          outlined
          flat
          hide-details
          dense
          clearable
          autofocus
          autocomplete="off"
          v-model="youtubeUrl"
          class="search-button ma-1 mx-2">
          <template #prepend-inner>
            <v-icon small>
              mdi-link
            </v-icon>
          </template>
          <template #label>
            Copia aquí el URL de tu video Youtube
          </template>
        </v-text-field>
      </v-card-text>
      <v-card-text
        v-if="video"
        class="px-5 py-2 d-flex align-start">
        <v-img
          :src="video.previewhq"
          :lazy-src="video.preview"
          aspect-ratio="1.33"
          class="grey lighten-2 rounded-lg mr-4"
          :width="$isMobile ? 120 : 230"
          :max-width="$isMobile ? 120 : 230">
          <template #placeholder>
            <v-row
              class="fill-height ma-0"
              align="center"
              justify="center">
              <v-progress-circular
                indeterminate
                color="grey lighten-5"
              ></v-progress-circular>
            </v-row>
          </template>
        </v-img>
        <div
          class="black--text pt-1">
          <div
            class="font-weight-bold mb-2"
            :class="$isMobile ? 'paragraph-ellipsis' : ''"
            style="--lines: 2;">
            {{ video.title }}
          </div>
          <div
            class="text-body-2 description"
            :class="$isMobile ? 'paragraph-ellipsis' : ''"
            style="--lines: 2;">
            {{ video.description }}
          </div>
        </div>
      </v-card-text>
      <v-card-actions
        class="pa-5 pt-3">
        <v-spacer />
        <v-btn
          depressed
          class="grey--text px-4"
          @click="close">
          Cancelar
        </v-btn>
        <v-btn
          :disabled="!loading && !video"
          :loading="waiting"
          @click="confirm"
          class="red white--text px-4">
          Confirmar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

import YouTube from '@/YouTube';
import albumController from '@/controllers/Album';

export default {
  name: 'VideoLoader',
  props: {
    albumId: {
      default: null,
    },
  },
  data() {
    return {
      dialog: false,
      resolve: null,
      reject: null,
      waiting: false,
      loading: false,
      youtubeUrl: null,
      video: null,
    };
  },
  watch: {
    youtubeUrl() {
      if (this.youtubeUrl) {
        this.loading = true;
        YouTube.getVideoData(this.youtubeUrl)
          .then((response) => {
            this.video = response;
            this.loading = false;
          });
      } else {
        this.video = null;
      }
    },
  },
  methods: {
    open() {
      this.youtubeUrl = null;
      this.video = null;
      this.dialog = true;
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    close() {
      this.youtubeUrl = null;
      this.resolve(false);
      this.dialog = false;
    },
    startWaiting() {
      this.waiting = true;
    },
    stopWaiting() {
      this.waiting = false;
    },
    confirm() {
      // start waiting
      this.startWaiting();
      // add video
      albumController.addVideo(
        this.albumId,
        this.video.url,
        this.video.title,
        this.video.description,
        this.video.id,
      ).then((response) => {
        const media = response.data;
        this.resolve(media);
        this.dialog = false;
        this.$success('Video guardado');
      }).catch((e) => {
        this.$error(e, 'No se pudo guardar el video. Intenta nuevamente.');
      }).then(() => {
        this.stopWaiting();
      });
    },
  },
};
</script>

<style lang="scss">

.video-loader-dialog {
  .v-text-field .v-label {
    max-width: 100%!important;
  }
}

</style>

<style lang="scss" scoped>

.video-loader-dialog {
  .description {
    word-break: break-word;
    max-height: 120px;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

</style>
