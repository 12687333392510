<template>
  <div
    class="categories-chips"
    :class="{services: showServices}">
    <category-chip
      v-for="(category, index) in categories"
      :key="index"
      :category="category"
      :full-width="fullWidth"
      :show-counter="showCounter"
      :show-services="showServices">
    </category-chip>
  </div>
</template>

<script>

import CategoryChip from './Category.vue';

export default {
  name: 'Categories',
  props: {
    categories: {
      type: Array,
      default: () => [],
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    showCounter: {
      type: Boolean,
      default: false,
    },
    showServices: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    CategoryChip,
  },
};
</script>

<style lang="scss" scoped>
.categories-chips {
  display: flex;

  &>div:not(:last-child) {
    margin-right: 6px;
  }

  @mixin flex_column {
    flex-direction: column;
    align-items: flex-start;

    &>div:not(:last-child) {
      margin-right: 0;
      margin-bottom: 0.8rem;
    }
  }

  &.services {
    @include flex_column;
  }
}
</style>
