<template>
  <div>
    <div
      class="paragraph mb-1"
      :class="[{'paragraph-ellipsis': truncated && !expanded}]"
      :style="`--lines: ${lines};`">
      {{ text }}
    </div>
    <div
      v-if="viewMore"
      class="text-caption">
      <a @click="toggle">
        <span v-if="!expanded">ver más</span>
        <span v-else>ver menos</span>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Paragraph',
  props: {
    text: {
      type: String,
      default: '',
    },
    truncated: {
      type: Boolean,
      default: false,
    },
    lines: {
      type: Number,
      default: 1,
    },
    viewMore: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      expanded: false,
    };
  },
  methods: {
    toggle() {
      this.expanded = !this.expanded;
    },
  },
};
</script>

<style lang="scss" scoped>
.paragraph {
  font-family: inherit;
  font-size: inherit;
}
</style>
