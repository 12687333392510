import Vue from 'vue';
import TextField from './TextField.vue';
import Textarea from './Textarea.vue';
import FileInput from './FileInput.vue';
import PriceInput from './PriceInput.vue';
import SearchInput from './SearchInput.vue';

Vue.component('e-text-field', TextField);
Vue.component('e-textarea', Textarea);
Vue.component('e-file-input', FileInput);
Vue.component('e-price-input', PriceInput);
Vue.component('e-search-input', SearchInput);
