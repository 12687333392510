import axios from 'axios';

export default {

  async keywords() {
    const url = '/api/v1/search/keywords';
    const response = await axios.get(url);
    return response.data;
  },

  async providers({
    q,
    page,
    size,
    c,
    s,
    p,
    d,
    l = false,
  }) {
    const url = '/api/v1/search/providers';
    const params = {};
    if (q) params.q = q; // query
    if (c) params.c = c; // category
    if (s) params.s = s; // service
    if (p) params.p = p; // province
    if (d) params.d = d; // district
    if (l) params.l = l; // logging
    if (page) params.page = page; // page
    if (size) params.size = size; // size
    const response = await axios.get(url, { params });
    return response.data;
  },

  async media({
    q,
    t,
    page,
    size,
    c,
    s,
    p,
    d,
    l = false,
  }) {
    const url = '/api/v1/search/media';
    const params = {};
    if (q) params.q = q; // query
    if (t) params.t = t; // type = photo | video
    if (c) params.c = c; // category
    if (s) params.s = s; // service
    if (p) params.p = p; // province
    if (d) params.d = d; // district
    if (l) params.l = l; // logging
    if (page) params.page = page; // page
    if (size) params.size = size; // size
    // if (params.length > 0) {
    //   url = `${url}?${params.join('&')}`;
    // }
    const response = await axios.get(url, { params });
    return response.data;
  },
};
