<template>
  <ValidationProvider
    :mode="mode"
    :name="name"
    :rules="rules"
    v-slot="{ errors }"
    class="e-textfield-wrapper">
    <v-text-field
      v-on="$listeners"
      v-model="innerValue"
      :type="type"
      :solo="solo"
      :flat="flat"
      :outlined="!filled"
      :filled="filled"
      :persistent-hint="persistentHint"
      :persistent-placeholder="persistentPlaceholder"
      :label="label"
      :placeholder="placeholder"
      :counter="counter"
      :hide-details="hideDetails"
      :full-width="fullWidth"
      :readonly="readonly"
      :clearable="clearable"
      :append-icon="appendIcon"
      :append-outer-icon="appendOuterIcon"
      :prepend-icon="prependIcon"
      :prepend-inner-icon="prependInnerIcon"
      :prefix="prefix"
      :autofocus="autofocus"
      :class="customClass"
      @change="$emit('input', $event)"
      :error-messages="errors[0]">
      <!-- pass named slots -->
      <template
        v-for="(slot, name) in $slots"
        :slot="name">
        <slot v-if="name === 'default'"></slot>
        <slot v-if="name !== 'default'" :name="name"></slot>
      </template>
      <!-- pass scoped slots -->
      <template
        v-for="(slot, name) in $scopedSlots"
        :slot="name"
        slot-scope="props">
        <slot :name="name" v-bind="props"></slot>
      </template>
    </v-text-field>
  </ValidationProvider>
</template>

<script>
export default {
  name: 'TextField',
  inheritAttrs: false,
  props: {
    name: null,
    value: null,
    rules: [String],
    mode: {
      type: String,
      default: 'eager',
    },
    type: {
      type: String,
      default: 'text',
    },
    label: {
      type: String,
      default: undefined,
    },
    placeholder: {
      type: String,
      default: undefined,
    },
    counter: {
      type: Number,
      default: undefined,
    },
    solo: {
      type: Boolean,
      default: false,
    },
    flat: {
      type: Boolean,
      default: false,
    },
    filled: {
      type: Boolean,
      default: true,
    },
    persistentHint: {
      type: Boolean,
      default: true,
    },
    persistentPlaceholder: {
      type: Boolean,
      default: false,
    },
    customClass: {
      type: String,
      default: '',
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    appendIcon: {
      type: String,
      default: undefined,
    },
    appendOuterIcon: {
      type: String,
      default: undefined,
    },
    prependIcon: {
      type: String,
      default: undefined,
    },
    prependInnerIcon: {
      type: String,
      default: undefined,
    },
    prefix: {
      type: String,
      default: undefined,
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      innerValue: this.value,
    };
  },
  watch: {
    value(val) {
      this.innerValue = val;
    },
  },
};
</script>

<style lang="scss" scoped>
.e-textfield-wrapper {
  width: 100%;
  border-radius: 4px;
  background-color: white;
}
</style>
