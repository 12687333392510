import Vue from 'vue';
import Select from './Select.vue';
import SelectEventTypes from './SelectEventTypes.vue';
import SelectDepartments from './SelectDepartments.vue';
import SelectProvinces from './SelectProvinces.vue';
import SelectDistricts from './SelectDistricts.vue';
import SelectPageSize from './SelectPageSize.vue';

import MultiSelect from './MultiSelect.vue';
import MultiSelectEventTypes from './MultiSelectEventTypes.vue';
import MultiSelectCategories from './MultiSelectCategories.vue';
import MultiSelectServices from './MultiSelectServices.vue';

Vue.component('e-select', Select);
Vue.component('event-types-select', SelectEventTypes);
Vue.component('departments-select', SelectDepartments);
Vue.component('provinces-select', SelectProvinces);
Vue.component('districts-select', SelectDistricts);
Vue.component('page-size-select', SelectPageSize);

Vue.component('e-multi-select', MultiSelect);
Vue.component('event-types-multi-select', MultiSelectEventTypes);
Vue.component('categories-multi-select', MultiSelectCategories);
Vue.component('services-multi-select', MultiSelectServices);
