// APP
export const TOGGLE_DEVICE = 'TOGGLE_DEVICE';
export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR';
export const OPEN_LOGIN = 'openLogin';
export const TOGGLE_SNACKBAR = 'TOGGLE_SNACKBAR';
export const TOGGLE_MOBILE_SEARCHER = 'TOGGLE_MOBILE_SEARCHER';
export const TOGGLE_SITE_EDITOR = 'TOGGLE_SIDE_EDITOR';

// EVENTEROS
export const EVENT_TYPES = 'eventTypes';
export const EVENT_TYPES_TOP = 'eventTypesTop';
export const SERVICE_TYPES = 'serviceTypes';
export const LOCATIONS = 'locations';
export const SELECTED_SERVICEGROUPS = 'selectedServiceGroups';
export const SELECTED_SERVICETYPES = 'selectedServiceTypes';
export const SELECTED_EVENTTYPES = 'selectedEventTypes';
export const SELECTED_LOCATIONS = 'selectedLocations';
export const QUOTATION = 'quotation';
export const NEW_PROVIDERS = 'newProviders';
export const FEATURED_PROVIDERS = 'featuredProviders';
export const COUNT_PROVIDERS = 'countProviders';
export const KEYWORDS = 'keywords';
export const PROVIDER_SITE = 'providerSite';
export const SIMILAR_PROVIDERS = 'similarProviders';
export const USERS = 'users';
