/* eslint-disable quote-props */
export default {
  'frontoffice.auth.welcome': '¡Bienvenido a Eventeros!',
  'frontoffice.auth.loginorsignup': 'Inicia sesión o regístrate con',
  'frontoffice.auth.acceptterms': 'Inicia sesión o regístrate con',
  'frontoffice.auth.termsandconditions': 'Términos y condiciones',
  'frontoffice.auth.and': 'y',
  'frontoffice.auth.privacy': 'Política de Privacidad',

  'frontoffice.404.message': 'Nada por aquí',

  'frontoffice.home.thanks': 'Gracias por pertenecer  Eventeros',

  'frontoffice.navbar.hello': 'Hola',
  'frontoffice.navbar.welcome': 'Bienvenido a Eventeros',

  'frontoffice.navbar.seemycompanyprofile': 'Ver el perfil de mi empresa',
  'frontoffice.navbar.logout': 'Cerrar Sesión',

  // user
  user: {
    male: 'Masculino',
    female: 'Femenino',
    other: 'Otro',
    unknow: 'Prefiero no decirlo',
  },

  // search tabs
  tab: {
    provider: 'Proveedor',
    photo: 'Foto',
    video: 'Video',
    providers: 'Proveedores',
    photos: 'Fotos',
    videos: 'Videos',
  },

  // media type
  photo: 'Foto',
  video: 'Video',
  photos: 'Fotos',
  videos: 'Videos',

  // calendar status:
  public: 'Público',
  private: 'Privado',

  // reviews
  reviews: {
    scale: {
      verygood: 'Muy bueno',
      good: 'Bueno',
      regular: 'Regular',
      bad: 'Malo',
      verybad: 'Muy malo',
    },
  },

};
