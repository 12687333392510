<template>
  <v-expansion-panels
    flat
    multiple
    v-model="panels"
    class="expansion-slipt">
    <v-expansion-panel
      class="expansion-panel">
      <v-expansion-panel-header
        class="panel-header box-shadow"
        :class="headerClass">
        {{ title }}
      </v-expansion-panel-header>
      <v-expansion-panel-content
        class="panel-content"
        :class="contentClass">
        <template
          v-if="!loading && !noContent">
          <div v-if="text">{{ text }}</div>
          <slot name="content"></slot>
        </template>
        <template
          v-else>
          <slot name="loader"></slot>
        </template>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>

export default {
  name: 'ExpansonSplit',
  props: {
    expanded: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: 'Título',
    },
    headerClass: {
      type: String,
      default: '',
    },
    contentClass: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    noContent: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      panels: [],
    };
  },
  created() {
    this.toggleExpanded();
  },
  watch: {
    expanded() {
      this.toggleExpanded();
    },
    panels(newVal) {
      this.$emit('expanded', newVal.length > 0);
    },
  },
  methods: {
    toggleExpanded() {
      if (this.expanded) {
        if (this.panels.length > 0) return;
        this.panels.push(0);
      } else {
        this.panels = [];
      }
    },
  },
};
</script>

<style lang="scss">

$panel-margin-bottom: 16px;
$border-radius: 6px;

.expansion-slipt {
  .expansion-panel {
    background-color: transparent!important;;
  }

  .panel-header {
    background-color: white;
    margin-bottom: $panel-margin-bottom;
    border-radius: $border-radius;
    min-height: 0;
  }

  .panel-content {
    background-color: white;
    margin-bottom: $panel-margin-bottom;
    border-radius: $border-radius;

    .v-expansion-panel-content__wrap {
      padding: 0!important;
    }
  }
}

</style>
