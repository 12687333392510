<template>
  <div
    class="like-button-wrapper">
    <v-btn
      ref="like"
      class="like-button"
      :elevation="fab ? 2 : 0"
      :fab="fab"
      :x-small="fab"
      :outlined="!fab"
      :color="fab ? undefined : 'grey darken-2'"
      :retain-focus-on-click="false"
      :ripple="false"
      :class=" {'liked': liked, 'px-2': !fab}"
      :loading="updating"
      :disabled="disabled"
      @click="$emit('click')">
      <v-icon
        size="16"
        style="margin-left: -2px">
        fa-heart-outline
      </v-icon>
      <div
        class="ml-2"
        v-if="!fab && !onlyIcon">
        Me gusta
      </div>
    </v-btn>
    <v-dialog
      absolute
      content-class="heart-dialog"
      v-model="dialog">
      <v-card
        flat
        tile
        width="100%"
        height="50vh"
        class="d-flex
          align-center
          justify-center
          overflow-hidden
          transparent">
        <v-icon
          color="white"
          size="80">
          fa-heart
        </v-icon>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import { mapActions } from 'vuex';

export default {
  name: 'LikeButton',
  props: {
    fab: {
      type: Boolean,
      default: false,
    },
    liked: {
      type: Boolean,
      default: false,
    },
    updating: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    onlyIcon: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      styleElement: null,
      dialog: false,
    };
  },
  computed: {
    style() {
      if (this.dialog) {
        return `
          .v-overlay.v-overlay--active {
            opacity: 0.8;
          }
        `;
      }
      return '';
    },
  },
  watch: {
    updating(newVal) {
      if (newVal) {
        const current = this.liked;
        if (current === false) {
          this.openBigHeart();
        }
      } else {
        this.closeBigHeart();
      }
    },
    style(style) {
      if (this.styleElement) {
        this.styleElement.parentNode.removeChild(this.styleElement);
      }
      this.styleElement = this.applyStyle(style);
    },
  },
  methods: {
    ...mapActions('app/', ['openLogin']),
    openBigHeart() {
      this.dialog = true;
    },
    closeBigHeart() {
      this.dialog = false;
    },
    applyStyle(styleDef) {
      const styleElement = document.createElement('style');
      document.head.appendChild(styleElement);
      styleElement.innerHTML = styleDef;
      return styleElement;
    },
  },
};
</script>

<style lang="scss">
.heart-dialog {
  box-shadow: none!important;
}
</style>

<style lang="scss" scoped>

.like-button {
  min-width: 0!important;

  &.liked {
    background-color: $brand-purple-lighten-1;
    color: white!important;
    border-color: $brand-purple-lighten-1;
  }
}
</style>
