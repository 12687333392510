const names = {
  auth: {
    email: 'Correo electrónico',
    password: 'Contraseña',
    new_password: 'Nueva contraseña',
    confirm_password: 'Confirmar contraseña',
    verification_code: 'Código de verificación',
  },
  user: {
    firstname: 'Nombres',
    lastname: 'Apellidos',
    gender: 'Género',
    dateOfBirth: 'Fecha de nacimiento',
  },
  service: {
    category: 'Categoría',
    serviceType: 'Servicio',
    description: 'Descripción del servicio',
    userEvent: 'Evento',
    eventType: 'Evento',
    eventDepartment: 'Departamento',
    eventProvince: 'Provincia',
    eventDistrict: 'Distrito',
    eventLocation: 'Ubicación',
    eventDate: 'Fecha',
  },
  quotation: {
    service: {
      price: 'Precio',
    },
    discount: 'Descuento',
    new_message: 'Nuevo mensaje',
    message: 'Mensaje',
    attachment: 'Adjunto PDF',
    attachment_file: 'Adjunto PDF',
    price: 'Precio',
  },
  provider: {
    code: 'Código de afiliación',
    email: 'Correo empresarial',
    company_name: 'Nombre de empresa',
    slug: 'URL de empresa',
    contact_name: 'Persona de contacto',
    contact_firstname: 'Nombres',
    contact_lastname: 'Apellidos',
    cellphone: 'Número celular',
    address_line1: 'Dirección 1',
    website: 'Sitio web',
    services: 'Servicios',
    introductory_text: 'Texto de presentación',

    testimonial: {
      customer_name: 'Nombre',
      testimonial: 'Testimonio',
      company_url: 'URL de empresa',
      photo: 'Foto',
    },

    socialLinks: {
      facebook: 'Facebook',
      instagram: 'Instagram',
      twitter: 'Twitter',
      linkedin: 'LinkedIn',
    },
  },
  review: {
    comment: 'Comentario',
    conditions: 'Política de Contenido',
    criterias: 'Criterios',
    response: 'Respuesta',
    cancel_reason: 'Razones de denuncia',
  },
  location: {
    department: 'Departamento',
    province: 'Provincia',
    district: 'Distrito',
  },
};

// convert json object to array of plain objects for names
const getObjectNames = (baseName, [key, value]) => {
  let plainNames = {};
  const fullName = baseName === '' ? key : `${baseName}.${key}`;
  if (typeof value !== 'object') {
    const obj = {};
    obj[fullName] = value;
    plainNames = { ...plainNames, ...obj };
  } else {
    const innerNames = value;
    Object.entries(innerNames).forEach((innerName) => {
      plainNames = { ...plainNames, ...getObjectNames(fullName, innerName) };
    });
  }
  return plainNames;
};

let plainNames = {};
Object.entries(names).forEach((name) => {
  plainNames = { ...plainNames, ...getObjectNames('', name) };
});

export default {
  ...plainNames,
};
