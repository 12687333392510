/* eslint-disable no-param-reassign */
import Vue from 'vue';

const containsDot = (value) => value?.match(/\./g);

const numberWithCommas = (x) => x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');

const capitalize = (value) => {
  if (!value) return '';
  return value.toString().charAt(0).toUpperCase() + value.toString().slice(1);
};

const leftPadded = (value, character, counter) => {
  if (!value) return '';
  if (!character) character = '0';
  if (!counter) counter = 4;
  return value.toString().padStart(counter, character);
};

const currency = (value, currencyCode, decimalPlaces = 2) => {
  if (!value) return '';
  if (!currencyCode) currencyCode = 'S/';
  const amount = parseFloat(value);
  let localeString = numberWithCommas(amount.toFixed(decimalPlaces).toLocaleString());
  if (!containsDot(localeString)) {
    if (decimalPlaces) {
      localeString = `${localeString}.${'0'.repeat(decimalPlaces)}`;
    }
  } else {
    const afterDot = (localeString.split('.')[1]);
    const repeatNumber = decimalPlaces > afterDot.length ? decimalPlaces - afterDot.length : 0;
    localeString = `${localeString}${'0'.repeat(repeatNumber)}`;
  }
  return `${currencyCode} ${localeString}`;
};

const integer = (value) => {
  const parsedInt = parseInt(value, 10);
  return Number.isNaN(parsedInt) ? 0 : parsedInt;
};

const $percent = (value, decimalPlaces = 2) => {
  const localeString = parseFloat(value)
    .toFixed(decimalPlaces)
    .toLocaleString();
  return `${localeString}%`;
};

Vue.filter('capitalize', capitalize);
Vue.filter('leftPadded', leftPadded);
Vue.filter('currency', currency);
Vue.filter('integer', integer);
Vue.filter('percent', $percent);

export {
  capitalize,
  leftPadded,
  currency,
  integer,
  $percent,
};
