import Vue from 'vue';
import ImageFullscreen from './ImageFullscreen.vue';
import PhotosSelector from './PhotosSelector.vue';
import PhotosSelectorItem from './PhotosSelectorItem.vue';
import VideoPlayer from './VideoPlayer.vue';
import VideoLoader from './VideoLoader.vue';

Vue.component('image-fullscreen', ImageFullscreen);
Vue.component('photos-selector', PhotosSelector);
Vue.component('photos-selector-item', PhotosSelectorItem);
Vue.component('video-player', VideoPlayer);
Vue.component('video-loader', VideoLoader);
