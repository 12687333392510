<template>
  <div
    class="pdf-thumbnail"
    :class="{
      'clickable': this.pdfUrl,
      'disabled': disabled
    }"
    @click="open()"
    :style="{
      'padding': `${padding}px`,
      'width': `${width}px`,
      'height': `${height}px`,
    }">
    <div class="canvas-wrapper"
      :style="{
        'width': `${width - padding * 2}px`,
        'height': `${height - padding * 2}px`,
      }">
      <canvas
        ref="pdfThumbnail"
        v-if="firstPage"
        :width="width - padding * 2"
        :height="height - padding * 2"
      />
      <div
        v-else>
        <v-icon
          color="grey">
          mdi-file-pdf-box
        </v-icon>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PDFThumbnail',
  props: {
    pdfFile: {
      type: File,
      default: null,
    },
    pdfUrl: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    padding: {
      type: Number,
      default: 4,
    },
    width: {
      type: Number,
      default: 55,
    },
    height: {
      type: Number,
      default: 77,
    },
  },
  data() {
    return {
      firstPage: null,
    };
  },
  watch: {
    pdfFile() {
      this.renderPDF();
    },
    pdfUrl() {
      this.renderPDF();
    },
  },
  created() {
    this.renderPDF();
  },
  methods: {
    open() {
      if (!this.pdfUrl || this.disabled) return;
      window.open(this.pdfUrl, '_blank');
    },
    renderPDF() {
      // Load the pdf.js library from a CDN.
      const pdfjs = window.pdfjsLib;

      // loading pdf
      let pdfDocument;
      if (this.pdfFile) {
        const fileReader = new FileReader();
        fileReader.addEventListener('load', () => {
          const typedarray = new Uint8Array(fileReader.result);
          pdfDocument = pdfjs.getDocument(typedarray);
          this.loadPromisePDF(pdfDocument);
        });
        fileReader.readAsArrayBuffer(this.pdfFile);
      }

      if (this.pdfUrl) {
        pdfDocument = pdfjs.getDocument(this.pdfUrl);
        this.loadPromisePDF(pdfDocument);
      }
    },
    loadPromisePDF(pdfDocument) {
      // Get the PDF document.
      pdfDocument.promise.then((pdfDoc) => {
        this.pdfDoc = pdfDoc;

        // Get the first page of the PDF document.
        pdfDoc.getPage(1).then((page) => {
          // load first page
          this.firstPage = page;

          this.$nextTick(() => {
            // Render the first page of the PDF document to a canvas element.
            const canvas = this.$refs.pdfThumbnail;
            const ctx = canvas.getContext('2d');

            const viewport = page.getViewport({ scale: 1 });
            const scaleX = canvas.width / viewport.width;
            const scaleY = canvas.height / viewport.height;
            const scale = Math.min(scaleX, scaleY);

            // Calculate the offset needed to center the content on the canvas.
            const offsetX = (canvas.width - viewport.width * scale) / 2;
            const offsetY = (canvas.height - viewport.height * scale) / 2;

            // Render the PDF page to the canvas, centered on the canvas.
            page.render({
              canvasContext: ctx,
              viewport: { scale: 1, transform: [scale, 0, 0, scale, offsetX, offsetY] },
            });
          });
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.pdf-thumbnail {
  &.clickable {
    cursor: pointer;
  }

  &.disabled {
    cursor: default;
  }

  .canvas-wrapper {
    border-radius: 4px;
    background-color: #f5f5f5;
    display: flex;
    align-items: center;
    justify-content: center;
    // box-shadow: 0px 0px 4px 0px rgba(209,209,209,1);
    // -webkit-box-shadow: 0px 0px 4px 0px rgba(209,209,209,1);
    // -moz-box-shadow: 0px 0px 4px 0px rgba(209,209,209,1);

    canvas {
      transform: rotateX(180deg);
    }
  }
}
</style>
