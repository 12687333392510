import Vue from 'vue';
// import axios from 'axios';

// const UPLOAD_GAPPS_URL = process.env.VUE_APP_UPLOAD_GAPPS_URL;
const FACEBOOK_CLIENT_ID = process.env.VUE_APP_FACEBOOK_CLIENT_ID;
const FACEBOOK_CLIENT_TOKEN = process.env.VUE_APP_FACEBOOK_CLIENT_TOKEN;

export default {
  /**
   * @param {{ [x: string]: any; }} o
   * @param {any[]} props
   */
  pick(o, ...props) {
    return Object
      .assign({}, ...props.map((prop) => ({ [prop]: o[prop] !== undefined ? o[prop] : null })));
  },
  loadFacebook() {
    window.fbAsyncInit = () => {
      window.FB.init({
        appId: FACEBOOK_CLIENT_ID,
        autoLogAppEvents: true,
        status: true,
        frictionlessRequests: true,
        xfbml: true,
        version: 'v7.0',
      });
      // this log page view causes a warning from facebook
      // window.FB.AppEvents.logPageView();
    };
    /* eslint-disable */
    (function (d, s, id) {
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) { return; }
      js = d.createElement(s); js.id = id;
      js.src = '//connect.facebook.net/es_LA/sdk.js';
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));
    /* eslint-enable */
  },
  /**
   * @param {any} fbLink
   * @param {(arg0: any) => void} callback
   */
  shareOnFacebook(fbLink, callback) {
    window.FB.ui({
      display: 'popup',
      method: 'share',
      href: fbLink,
    },
    /**
     * @param {any} response
     */
    (response) => {
      if (callback) {
        callback(response);
      }
    });
  },
  scrapeOnFacebook(fbLink, callback) {
    const clientToken = `${FACEBOOK_CLIENT_ID}|${FACEBOOK_CLIENT_TOKEN}`;
    window.FB.api(
      '/',
      'POST',
      {
        scrape: 'true',
        id: fbLink,
        access_token: clientToken,
      },
      (response) => {
        if (callback) {
          callback(response);
        }
      },
    );
  },
  /**
   * @param {any} eventName
   */
  trackEvent(eventName) {
    Vue.gtm.trackEvent({ event: eventName });
  },
  /**
   * @param {any} file
   */
  fileToDataUri(file) {
    return new Promise((resolve) => {
      const reader = new FileReader();

      reader.addEventListener('load', () => {
        resolve(reader.result);
      });

      reader.readAsDataURL(file);
    });
  },
  /**
   * @param {any} dataUri
   */
  dataUriToImage(dataUri) {
    return new Promise((resolve) => {
      // create Image
      const img = new Image();
      img.addEventListener('load', () => {
        resolve(img);
      });
      // @ts-ignore
      img.src = dataUri;
    });
  },
  /**
   * @param {any} file
   */
  async fileToImage(file) {
    const dataUri = await this.fileToDataUri(file);
    const img = await this.dataUriToImage(dataUri);
    return img;
  },
  /**
   * @param {any} img
   * @param {any} size
   * @param {any} width
   * @param {any} quality
   * @param {any} mimeType
   */
  resizeImage(img, size, resizeWidth, resizedQuality, mimeType = 'image/jpeg') {
    return new Promise((resolve) => {
      const scaleFactor = resizeWidth / img.width;
      const resizedHeight = img.height * scaleFactor;

      const imageAspectRatio = img.width / img.height;

      const mustResize = (imageAspectRatio >= 1 && img.width > resizeWidth)
        || (imageAspectRatio < 1 && img.height > resizeWidth);

      if (mustResize) {
        createImageBitmap(img, {
          resizeWidth,
          resizedHeight,
          resizeQuality: resizedQuality,
        }).then((imageData) => {
          const canvas = document.createElement('canvas');

          // set canvas context
          const ctx = canvas.getContext('2d');

          // Image Size After Scaling
          // @ts-ignore
          canvas.setAttribute('width', imageData.width);
          // @ts-ignore
          canvas.setAttribute('height', imageData.height);

          // img.width and img.height will contain the original dimensions
          ctx.drawImage(img, 0, 0, imageData.width, imageData.height);

          const resizedDataUrl = canvas.toDataURL(mimeType);

          const contentWithoutMime = resizedDataUrl.split(',')[1];
          const sizeInBytes = window.atob(contentWithoutMime).length;

          resolve({
            width: imageData.width,
            height: imageData.height,
            size: sizeInBytes,
            dataUrl: resizedDataUrl,
            mimeType,
          });
        });
      } else {
        resolve({
          width: img.width,
          height: img.height,
          size,
          dataUrl: img.src,
          mimeType,
        });
      }
    });
  },
  /**
   * @param {number} min
   * @param {number} max
   */
  randomNumber(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  },
  async validateSize(files, minWidth, minHeight) {
    /* eslint-disable no-throw-literal, no-param-reassign */
    let error;
    try {
      await Promise.all(Array.from(files).map(async (file) => {
        const image = await this.fileToImage(file);
        const imageAspectRatio = image.width / image.height;
        if (imageAspectRatio < 1) {
          const newMinHeight = minWidth;
          minWidth = minHeight;
          minHeight = newMinHeight;
        }
        if (image.width < minWidth) {
          throw `El ancho mínimo es de ${minWidth}px. Selecciona otra foto.`;
        }
        if (image.height < minHeight) {
          throw `El alto mínimo es de ${minHeight}px. Selecciona otra foto.`;
        }
      }));
    } catch (e) {
      error = e;
    }
    return new Promise((resolve, reject) => {
      if (error) {
        reject(new Error(error));
      } else {
        resolve(true);
      }
    });
    /* eslint-enable no-throw-literal, no-param-reassign */
  },
  toggleEnableHtmlScrollbar(enabled) {
    const html = document.getElementsByTagName('html')[0];
    if (enabled) {
      html.classList.remove('overflow-y-hidden');
    } else {
      html.classList.add('overflow-y-hidden');
    }
  },
};
