import Vue from 'vue';
import VueCookie from 'vue-cookie';
import moment from 'moment-timezone';
import VueMoment from 'vue-moment';
import VueMeta from 'vue-meta';
import VueSanitize from 'vue-sanitize';
import VueScrollTo from 'vue-scrollto';
import VueGtm from 'vue-gtm';
import NProgress from 'vue-nprogress';
import VueApollo from 'vue-apollo';
import VueMask from 'v-mask';
import VueFlicking from '@egjs/vue-flicking';
import apolloClient from './vue-apollo';

import App from './App.vue';
import router from './router';
import i18n from './i18n';
import store from './store';
import vuetify from './plugins/vuetify';

import './plugins';
import './validators';
import './controls';
import './filters';
import './auth';

Vue.config.productionTip = false;

Vue.use(NProgress);
Vue.use(VueCookie);
Vue.use(VueMoment, {
  moment,
});
Vue.use(VueMeta);
Vue.use(VueSanitize);
Vue.use(VueGtm, {
  id: 'GTM-KH34PPN',
  enabled: false,
  vueRouter: router,
});
Vue.use(VueFlicking);
Vue.use(VueScrollTo, {
  container: 'body',
  duration: 500,
  easing: 'ease',
  offset: 0,
  force: true,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true,
});
Vue.use(VueMask);
Vue.use(VueApollo);

const nprogress = new NProgress({ parent: '.nprogress-container' });

// create apollo client from vue.apollo.js
const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
});

router.beforeEach((to, from, next) => {
  if (to.name !== 'login' && to.name !== 'oauth2-callback') {
    Vue.cookie.set('next', to.fullPath, 1); // next url
  }
  next();
});

// enable Google Tag Manager (GTM)
if (process.env.NODE_ENV === 'production') {
  Vue.gtm.enable(true);
}

Vue.prototype.$isProduction = process.env.VUE_APP_ENV === 'production';
Vue.prototype.$isStaging = process.env.VUE_APP_ENV === 'staging';
Vue.prototype.$isDevelopment = process.env.VUE_APP_ENV === 'development';

// create Vue client
new Vue({
  router,
  store,
  i18n,
  nprogress,
  vuetify,
  apolloProvider,
  render: (h) => h(App),
}).$mount('#app');
