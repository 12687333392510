<template>
  <ValidationProvider
    :mode="mode"
    :name="name"
    :rules="rules"
    v-slot="{ errors }">
    <v-select
      v-on="$listeners"
      :filled="filled"
      :label="label"
      :items="items"
      :item-text="itemText"
      :item-value="itemValue"
      :hide-details="hideDetails"
      :full-width="fullWidth"
      :readonly="readonly"
      :clearable="clearable"
      v-model="innerValue"
      return-object
      :error-messages="errors[0]">
      <!-- pass named slots -->
      <template
        v-for="(slot, name) in $slots"
        :slot="name">
        <slot v-if="name === 'default'"></slot>
        <slot v-if="name !== 'default'" :name="name"></slot>
      </template>
      <!-- pass scoped slots -->
      <template
        v-for="(slot, name) in $scopedSlots"
        :slot="name"
        slot-scope="props">
        <slot :name="name" v-bind="props"></slot>
      </template>
    </v-select>
  </ValidationProvider>
</template>

<script>
export default {
  name: 'Select',
  inheritAttrs: false,
  props: {
    items: {
      type: Array,
      required: true,
      default: () => [],
    },
    itemText: {
      type: String,
      required: true,
      default: null,
    },
    itemValue: {
      type: String,
      required: true,
      default: null,
    },
    label: {
      type: String,
      required: false,
      default: 'Seleccionar',
    },
    name: {
      type: String,
      required: true,
      default: null,
    },
    value: {
      type: [Number, String, Array, Object],
      required: false,
      default: null,
    },
    rules: [String],
    mode: {
      type: String,
      default: 'eager',
    },
    filled: {
      type: Boolean,
      default: true,
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      innerValue: this.value,
    };
  },
  watch: {
    value(val) {
      this.innerValue = val;
    },
  },
};
</script>
