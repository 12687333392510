import Vue from 'vue';
import Title from './Title.vue';
import Subtitle from './Subtitle.vue';
import Paragraph from './Paragraph.vue';
import Label from './Label.vue';

Vue.component('e-title', Title);
Vue.component('e-subtitle', Subtitle);
Vue.component('e-paragraph', Paragraph);
Vue.component('e-label', Label);
