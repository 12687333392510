<template>
  <div>
    <template
      v-if="$vuetify.breakpoint.xs">
      <template
        v-for="(category, index) in categories">
        <div
          :key="index">
          <div
            class="font-weight-semibold py-0"
            style="letter-spacing: 0 !important">
            {{ category }}
          </div>
          <div
            class="py-2">
            <e-checkbox
              class="ma-0 mb-2"
              :disabled="sending"
              :label="serviceType.type_name"
              :value="serviceType.id"
              v-model="serviceTypeIds"
              v-for="serviceType in servicesByGroup(category)"
              :key="serviceType.id"
            ></e-checkbox>
          </div>
        </div>
      </template>
    </template>
    <template
      v-if="$vuetify.breakpoint.smAndUp">
      <vue-masonry-wall
        ref="masonry"
        :items="categories"
        :options="{width: 300, padding: 12}"
        v-if="categories.length > 0">
        <template v-slot:default="{item}">
          <div class="item">
            <div
              class="text-title font-weight-bold mb-4">
              {{item}}
            </div>
            <div>
              <e-checkbox
                class="ma-0 mb-2"
                :disabled="sending"
                :label="serviceType.type_name"
                :value="serviceType.id"
                v-model="serviceTypeIds"
                v-for="serviceType in servicesByGroup(item)"
                :key="serviceType.id"
              ></e-checkbox>
            </div>
          </div>
        </template>
      </vue-masonry-wall>
    </template>
    <ValidationProvider
      mode="eager"
      v-slot="{ errors }"
      rules="min_value:1"
      vid="services.selector">
      <div>
        <input
          style="visibility: hidden"
          type="text"
          v-model="selectedCount"
          name="services.selector" />
        <field-error
          v-if="errors[0]">
          {{ errors[0] }}
        </field-error>
      </div>
    </ValidationProvider>
  </div>
</template>

<script>

import { mapState, mapActions } from 'vuex';
import VueMasonryWall from 'vue-masonry-wall';

export default {
  name: 'ServicesSelector',
  props: {
    sending: {
      default: false,
    },
    value: {
      default: [],
    },
  },
  data() {
    return {
      selectedCount: 0,
      serviceTypeIds: [],
      categories: [],
    };
  },
  created() {
    this.serviceTypeIds = this.$deepCopy(this.value);
    this.$nextTick(async () => {
      await this.refreshServiceTypes();
      this.categories = this.serviceTypes
        .map((item) => item.group_name)
        .filter((value, index, self) => self.indexOf(value) === index) // array distinct
        .sort();
    });
  },
  watch: {
    serviceTypeIds() {
      this.$emit('input', this.serviceTypeIds);
      this.selectedCount = this.serviceTypeIds.length;
    },
  },
  computed: {
    ...mapState('eventeros/', ['serviceTypes']),
  },
  methods: {
    ...mapActions('eventeros/', ['refreshServiceTypes']),
    servicesByGroup(groupName) {
      return this.serviceTypes
        .filter((p) => p.group_name === groupName)
        .sort((a, b) => a.type_name.localeCompare(b.type_name));
    },
  },
  components: {
    VueMasonryWall,
  },
};
</script>
