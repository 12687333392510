<template>
  <div class="price-input">
    <e-text-field
      :name="name"
      :rules="rules"
      :mode="mode"
      :label="label"
      :full-width="false"
      :prefix="currencyPrefix"
      :autofocus="autofocus"
      @keypress="onlyNumber"
      @input="handleInput"
      @change="handleChange"
      @focus="handleFocus"
      v-model="amount"
    ></e-text-field>
    <v-checkbox
      hide-details
      class="text-caption"
      v-model="include_igv"
      :label="includeIGVLabel"
      @change="$emit('input', { amount, include_igv: $event })"
    ></v-checkbox>
  </div>
</template>

<script>

const supportedCurrencies = Object.freeze({
  PEN: 'S/',
  USD: '$',
});

export default {
  name: 'PriceInput',
  inheritAttrs: false,
  props: {
    name: null,
    rules: [String],
    mode: {
      type: String,
      default: 'eager',
    },
    label: {
      type: String,
      default: 'Precio',
    },
    includeIGVLabel: {
      type: String,
      default: 'Incluye IGV',
    },
    currencyCode: {
      type: String,
      default: 'PEN',
    },
    integerPlaces: {
      type: Number,
      default: 5,
    },
    decimalPlaces: {
      type: Number,
      default: 2,
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      amount: null,
      include_igv: true,
      maxLength: this.integerPlaces,
      currencyPrefix: null,
    };
  },
  methods: {
    onlyNumber($event) {
      const keyCode = ($event.keyCode ? $event.keyCode : $event.which);
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) { // 46 is dot
        $event.preventDefault();
      }
      if (keyCode === 46) {
        const { value } = $event.target;
        if (this.containsDot(value)) {
          $event.preventDefault();
        }
      }
    },
    handleChange() {
      // emit change
      this.$emit('input', {
        amount: this.amount ? parseFloat(this.amount) : null,
        include_igv: this.include_igv,
      });
      // format
      this.amount = this.formatCurrency(this.amount);
      // prefix
      this.currencyPrefix = this.amount
        ? supportedCurrencies[this.currencyCode]
        : null;
    },
    formatCurrency(value) {
      if (!value) return '';
      let localeString = parseFloat(value).toLocaleString();
      if (!this.containsDot(localeString)) {
        localeString = `${localeString}.${'0'.repeat(this.decimalPlaces)}`;
      } else {
        const afterDot = (localeString.split('.')[1]);
        localeString = `${localeString}${'0'.repeat(this.decimalPlaces - afterDot.length)}`;
      }
      return localeString;
    },
    handleInput(value) {
      const beforeDot = (value.split('.')[0]);
      const afterDot = (value.split('.')[1]);
      // truncate integer places
      if (beforeDot?.length > this.integerPlaces) {
        this.$nextTick(() => {
          const newBeforeDot = beforeDot.slice(0, this.integerPlaces);
          if (this.containsDot(value)) {
            this.amount = value.replace(`${beforeDot}.`, `${newBeforeDot}.`);
          } else {
            this.amount = value.replace(`${beforeDot}`, `${newBeforeDot}`);
          }
        });
      }
      // truncate decimal places
      if (afterDot?.length > this.decimalPlaces) {
        this.$nextTick(() => {
          const newAfterDot = afterDot.slice(0, this.decimalPlaces);
          this.amount = value.replace(`.${afterDot}`, `.${newAfterDot}`);
        });
      }
    },
    handleFocus(event) {
      if (!this.amount) return;
      this.amount = this.amount.replace(',', '');
      this.$nextTick(() => {
        event.target.select();
      });
    },
    containsDot(value) {
      return value?.match(/\./g);
    },
  },
};
</script>

<style lang="scss" scoped>
.price-input {
  display: flex;
  gap: 8px;
  flex-grow: 1;

  .text-caption {
    min-width: 110px;
  }
}
</style>
