<template>
  <v-fade-transition>
    <div
      class="v-messages theme--light error--text mb-4" role="alert">
      <div class="v-messages__wrapper">
        <div class="v-messages__message" style="">
          <slot></slot>
        </div>
      </div>
    </div>
  </v-fade-transition>
</template>

<script>
export default {
  name: 'FieldError',
};
</script>
