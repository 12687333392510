<template>
  <div
    :key="componentKey"
    class="photos-selector"
    :class="{'dashed': dashed, 'pickable': pickable}"
    @click="pickFile">
    <!-- pick files -->
    <div
      class="pick-files"
      v-if="pickable && media.length === 0">
      <v-icon
        size="18"
        class="mr-1">
        fa-photos
      </v-icon>
      <a
        class="grey--text text--darken-1"
        :class="{'disabled': media.length >= maxImages}">
        <span>{{ label }} </span>
        <span
          v-if="optional"
          class="text-caption">
          (opcional)
        </span>
      </a>
    </div>
    <!-- input hidden to pick files -->
    <input
      type="file"
      style="display: none"
      ref="images"
      :accept="extensions"
      multiple
      @change="onPicked">
    <!-- no images -->
    <div
      v-if="!noImagesShadow && noImagesMessage && !pickable && media.length === 0">
      {{ noImagesMessage }}
    </div>
    <div
      class="no-images-shadows"
      v-if="noImagesShadow & !pickable && media.length === 0">
      <template
        v-for="index in maxImages">
        <photos-selector-item
          :key="index"
          :index="index">
        </photos-selector-item>
      </template>
    </div>
    <!-- thumbnails -->
    <div
      class="media-gallery"
      :class="{
        'nice-scrollbar': scrollable,
        'overflow-hidden': !scrollable,
        'clearable': clearable,
        'no-wrap': noWrap,
      }"
      :style="`gap: ${gap}`"
      v-if="media && media.length > 0">
      <template
        v-for="(item, index) in media">
        <photos-selector-item
          :key="index"
          :index="index"
          :data-url="item.data_url"
          :thumbnail="item.thumbnail"
          :uploading="item.uploading"
          :uploaded="item.uploaded"
          :clearable="clearable"
          :clickable="true"
          @cleared="onCleared"
          @clicked="onClicked">
        </photos-selector-item>
      </template>
      <v-dialog
        v-if="carousel"
        max-width="800"
        v-model="opened"
        content-class="photos-selector-slider">
        <v-carousel
          hide-delimiters>
          <v-carousel-item
            v-for="(item,i) in media"
            :key="i"
            :src="item.path"
            reverse-transition="fade-transition"
            transition="fade-transition"
            :active-class="item.aspect_ratio < 1 ? 'image-background__contain' : ''"
          ></v-carousel-item>
        </v-carousel>
      </v-dialog>
    </div>
  </div>
</template>

<script>

import { isEqual } from 'lodash';
import Utils from '@/Utils';
import cdnController from '@/controllers/Cdn';
import albumController from '@/controllers/Album';

export default {
  name: 'PhotosSelector',
  props: {
    value: {
      default: null,
    },
    label: {
      type: String,
      default: 'seleccionar fotos',
    },
    optional: {
      type: Boolean,
      default: true,
    },
    maxImages: {
      type: Number,
      default: 5,
    },
    pickable: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    noImagesMessage: {
      type: String,
      default: 'Ninguna',
    },
    noImagesShadow: {
      type: Boolean,
      default: false,
    },
    gap: {
      type: String,
      default: '8px',
    },
    dashed: {
      type: Boolean,
      default: false,
    },
    scrollable: {
      type: Boolean,
      default: true,
    },
    noWrap: {
      type: Boolean,
      default: false,
    },
    carousel: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      componentKey: 0,
      initialMedia: [],
      media: [],
      files: [],
      minWidth: 600,
      minHeight: 400,
      resizeWidth: 1920,
      resizeQuality: 'high',
      extensions: '.png,.jpg,.jpeg',
      selected: null,
      opened: false,
    };
  },
  computed: {
    deleted() {
      const selectedIds = this.media.map((m) => m.id);
      const d = this.initialMedia
        .filter((p) => !selectedIds.includes(p.id));
      return d;
    },
    added() {
      return this.media
        .filter((p) => p.path === null && p.data_url !== null);
    },
  },
  watch: {
    value(val) {
      if (!isEqual(val, this.media)) {
        this.initData();
        this.reload();
      }
    },
  },
  created() {
    this.initData();
  },
  methods: {
    reload() {
      this.componentKey += 0;
    },
    initData() {
      this.initialMedia = this.$deepCopy(this.value);
      this.media = this.$deepCopy(this.value);
    },
    pickFile() {
      if (this.pickable && this.media.length < this.maxImages) {
        this.$refs.images.click();
      }
    },
    onPicked(e) {
      // get files from input
      const { files } = e.target;
      const counter = Array.from(files).length;
      if (this.media.length + counter > this.maxImages) {
        this.$error(`Solo puedes adjuntar un máximo de ${this.maxImages} fotos`);
        return;
      }
      Utils.validateSize(files, this.minWidth, this.minHeight)
        .then(() => {
          // load files to upload (used for uploading)
          this.files = Array.from(files).map((p) => p.name);
          // iterate list of files and add to the library
          this.$nextTick(() => {
            Array.from(files).forEach(async (f) => {
              this.push(f);
            });
          });
        })
        .catch((error) => {
          this.$error(error.message);
          this.$refs.images.value = null;
        });
    },
    onCleared(index) {
      this.media.splice(index, 1);
      this.$emit('input', this.media);
    },
    onClicked() {
      if (this.carousel) {
        this.opened = true;
      }
    },
    async push(file) {
      const fileName = file.name;
      // resize image to high definition resolution
      const resizedImage = await this.resize(file, this.resizeWidth, this.resizeQuality);
      // create media item object
      const mediaItem = await this.create(fileName, resizedImage);
      // add media item to array
      this.media = [...this.media, ...[mediaItem]];
      // emit input event
      this.$emit('input', this.media);
    },
    async uploadOne(item, albumId) {
      /* eslint-disable no-param-reassign */
      return new Promise((resolve) => {
        item.uploading = true;
        cdnController.uploadPhoto(item.fileName, item.data_url, albumId)
          .then((uploadedFile) => {
            // update item with the external ID and url
            item.external_id = uploadedFile.id;
            item.path = uploadedFile.url;
            // save item in database
            return this.save(item, albumId);
          })
          .then((savedMedia) => {
            item.id = savedMedia.id;
            item.album_id = savedMedia.album_id;
            item.path = savedMedia.path;
            item.thumbnail = savedMedia.thumbnail;
            item.aspect_ratio = savedMedia.aspect_ratio;
            item.sizes = savedMedia.sizes;
            item.uploaded = true;
            this.$emit('added', item);
            resolve('item');
          })
          .catch((e) => {
            item.failed = true;
            item.error = e;
          })
          .then(() => {
            item.uploading = false;
          });
      });
    },
    upload(albumId) {
      /* eslint-disable no-param-reassign */
      return new Promise((resolve, reject) => {
        if (typeof albumId !== 'number') {
          reject(new Error('Album no definido'));
        } else {
          const uploader = this.added
            .map((image) => this.uploadOne(image, albumId));
          Promise.all(uploader)
            .then(async () => {
              const response = {
                selected: this.media.length,
                uploaded: this.media.filter((p) => p.uploaded).length,
                failed: this.media.filter((p) => p.failed).length,
                media: this.media,
              };
              resolve(response);
            });
        }
      });
    },
    saveAll(albumId, mustPurge = false) {
      return new Promise((resolve, reject) => {
        // exit if there is nothing to save
        if (this.added.length === 0 && this.deleted.length === 0) {
          resolve(undefined);
        }
        // validate if album_id exists
        if (typeof albumId !== 'number') {
          reject(new Error('Album no definido'));
          return;
        }
        // create processor
        const processor = [
          ...this.added.map((image) => this.uploadOne(image, albumId)),
          ...this.deleted.map((deleted) => this.delete(deleted.id, albumId, mustPurge)),
        ];
        // promise all
        Promise.all(processor)
          .then(async () => {
            const response = {
              selected: this.media.length,
              uploaded: this.media.filter((p) => p.uploaded).length,
              failed: this.media.filter((p) => p.failed).length,
              deleted: this.deleted.length,
              media: this.media,
            };
            resolve(response);
          });
      });
    },
    resize(file, width, quality) {
      return new Promise((resolve) => {
        Utils.fileToImage(file)
          .then((image) => Utils.resizeImage(
            image,
            file.size,
            width,
            quality,
          ))
          .then(async (resizedImage) => {
            resolve(resizedImage);
          });
      });
    },
    create(filename, image) {
      // calculate a temporary id
      const maxId = Date.now();
      // create new media object
      return new Promise((resolve) => {
        const newMedia = {
          id: maxId + 1,
          data_url: image.dataUrl,
          filename,
          seo_filename: '',
          description: '',
          mime_type: image.mimeType,
          original_filename: filename,
          external_id: null,
          selected: false,
          media_type: 'photo',
          sort_order: 0,
          path: null,
          thumbnail: null,
          sizes: null,
          width: image.width,
          height: image.height,
          size: image.size, // bytes
          uploading: false,
        };
        resolve(newMedia);
      });
    },
    save(newMedia, albumId) {
      return new Promise((resolve) => {
        albumController.addMedia(
          albumId,
          newMedia,
        ).then((response) => {
          resolve(response.data);
        });
      });
    },
    delete(deletedId, albumId, mustPurge) {
      return albumController.deleteMedia(albumId, deletedId, mustPurge);
    },
  },
};
</script>

<style lang="scss" scoped>
.photos-selector {
  flex-grow: 1;
  overflow: hidden;
  display: flex;
  justify-content: center;
  flex-direction: column;

  &.pickable {
    cursor: pointer;
  }

  // dashed
  &.dashed {
    min-height: 80px;
    border-radius: 8px;
    justify-content: center;
    padding: 0 8px;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='6' ry='6' stroke='%23333' stroke-width='2' stroke-dasharray='6%2c 10' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");

    // dashed
    .pick-files {
      text-align: center;
    }
  }

  .media-gallery {
    display: flex;
    flex-wrap: wrap;
    flex-grow: 0;
    overflow: scroll;

    &.clearable {
      padding: 8px 0; // important for clear icons
    }

    &.no-wrap {
      flex-wrap: nowrap;
    }
  }

  .no-images-shadows {
    display: flex;
    gap: 4px;
  }
}
</style>

<style lang="scss">
.photos-selector-slider {
  background-color: black;

  .image-background__contain {
    .v-image__image {
      background-size: contain!important;
    }
  }
}
</style>
