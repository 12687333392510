<template>
  <v-dialog
    content-class="ma-0 overflow-hidden rounded-0"
    v-model="show"
    :persistent="persistent"
    :width="$vuetify.breakpoint.xs ? '100%' : '60%'"
  >
    <v-card
      flat
      tile
      v-if="show"
      class="pa-0 transparent"
      v-click-outside="clickOutside"
    >
      <v-card-text
        class="pa-0"
      >
        <v-img
          max-height="800"
          :src="cover.path"
          :lazy-src="cover.sizes.hq"
          :aspect-ratio="cover.aspect_ratio"
          :contain="cover.aspect_ratio < 1"
        >
          <template #placeholder>
            <v-row
              class="fill-height ma-0"
              align="center"
              justify="center"
            >
              <v-progress-circular
                indeterminate
                color="grey lighten-5"
              />
            </v-row>
          </template>
        </v-img>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>

import FullscreenMixin from '@/mixins/screen';

export default {
  name: 'ImageFullscreen',
  mixins: [
    FullscreenMixin,
  ],
  props: {
    initialShow: {
      default: false,
    },
    cover: {
      default: null,
    },
    persistent: {
      default: false,
    },
  },
  data() {
    return {
      show: this.initialShow,
    };
  },
  watch: {
    initialShow() {
      this.show = this.initialShow;
    },
    show(newValue) {
      if (newValue) {
        this.enterFullscreen();
      } else {
        this.$emit('closed');
        this.exitFullscreen();
      }
    },
  },
  methods: {
    clickOutside() {
      this.show = false;
    },
  },
};
</script>
