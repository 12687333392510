<template>
  <v-checkbox
    v-on="$listeners"
    :color="color"
    :label="label"
    :value="value"
    :disabled="disabled"
    v-model="innerInputValue"
    :hide-details="hideDetails"
    off-icon="fa-checkbox-unchecked"
    on-icon="fa-checkbox-checked"
    @change="$emit('input', $event);"
  >
    <!-- pass named slots -->
    <template
      v-for="(slot, name) in $slots"
      :slot="name">
      <slot v-if="name === 'default'"></slot>
      <slot v-if="name !== 'default'" :name="name"></slot>
    </template>
    <!-- pass scoped slots -->
    <template
      v-for="(slot, name) in $scopedSlots"
      :slot="name"
      slot-scope="props">
      <slot :name="name" v-bind="props"></slot>
    </template>
  </v-checkbox>
</template>

<script>
export default {
  name: 'Checkbox',
  model: {
    prop: 'inputValue',
    event: 'input',
  },
  props: {
    color: {
      type: String,
      default: '#A97AB0',
    },
    label: {
      type: String,
      default: '',
    },
    value: {
      type: [String, Number],
      default: null,
    },
    inputValue: {
      type: [Array, Boolean],
      default: null,
    },
    hideDetails: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      innerInputValue: this.inputValue,
    };
  },
  watch: {
    inputValue() {
      this.innerInputValue = this.inputValue;
    },
  },
};
</script>
