var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pdf-thumbnail",class:{
    'clickable': this.pdfUrl,
    'disabled': _vm.disabled
  },style:({
    'padding': (_vm.padding + "px"),
    'width': (_vm.width + "px"),
    'height': (_vm.height + "px"),
  }),on:{"click":function($event){return _vm.open()}}},[_c('div',{staticClass:"canvas-wrapper",style:({
      'width': ((_vm.width - _vm.padding * 2) + "px"),
      'height': ((_vm.height - _vm.padding * 2) + "px"),
    })},[(_vm.firstPage)?_c('canvas',{ref:"pdfThumbnail",attrs:{"width":_vm.width - _vm.padding * 2,"height":_vm.height - _vm.padding * 2}}):_c('div',[_c('v-icon',{attrs:{"color":"grey"}},[_vm._v(" mdi-file-pdf-box ")])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }