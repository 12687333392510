import Vue from 'vue';
import VueI18n from 'vue-i18n';
import es from './es';

Vue.use(VueI18n);

const messages = {
  es,
};

export default new VueI18n({
  locale: 'es', // set locale
  messages, // set locale messages
});
