<template>
  <div
    class="e-title"
    :class="[{'paragraph-ellipsis': !wrapText}]"
    :style="`
      font-size: ${fontSize};
      font-weight: ${fontWeight};
      --lines: 1;
      --word-break: break-all`"
    v-on="$listeners">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'Title',
  props: {
    fontSize: {
      type: String,
      default: '1.15rem',
    },
    fontWeight: {
      type: String,
      default: '600',
    },
    wrapText: {
      type: Boolean,
      default: true,
    },
    lines: {
      type: Number,
      default: 2,
    },
  },
};
</script>

<style lang="scss" scoped>
.e-title {
  font-family: "Work Sans", sans-serif !important;
}
</style>
