import axios from 'axios';

const YOUTUBE_APIKEY = process.env.VUE_APP_YOUTUBE_APIKEY;

export default {

  // https://www.youtube.com/watch?v=dmVAmlpbnD4
  // https://youtu.be/dmVAmlpbnD4
  // https://www.youtube.com/embed/dmVAmlpbnD4

  // http://img.youtube.com/vi/ID/0.jpg               480x360
  // http://img.youtube.com/vi/ID/1.jpg               120x90
  // http://img.youtube.com/vi/ID/2.jpg               120x90
  // http://img.youtube.com/vi/ID/3.jpg               120x90

  // sizes:
  // http://img.youtube.com/vi/ID/default.jpg         120x90    - xs
  // http://img.youtube.com/vi/ID/mqdefault.jpg       320x180   - sm
  // http://img.youtube.com/vi/ID/hqdefault.jpg       480x360   - md
  // http://img.youtube.com/vi/ID/sddefault.jpg       640x480   - lg
  // http://img.youtube.com/vi/ID/maxresdefault.jpg   1280x720  - xl

  getThumbnail(url, size) {
    // this returns the default thumbnail
    if (url === null) {
      return '';
    }
    const videoId = this.getVideoId(url);

    let preffix = '';
    switch (size) {
      case 'sm':
        preffix = 'mq';
        break;
      case 'md':
        preffix = 'hq';
        break;
      case 'lg':
        preffix = 'sd';
        break;
      case 'xl':
        preffix = 'maxres';
        break;
      default:
        preffix = '';
        break;
    }

    return `https://img.youtube.com/vi/${videoId}/${preffix}default.jpg`;
  },

  getVideoId(url) {
    const youtubeRegex = /(?:youtube\.com\/(?:[^/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/|youtube\.com\/live\/)([^"&?\s]{11})/i;
    const results = url.match(youtubeRegex);
    return (results === null) ? null : results[1];
  },

  async getVideoData(url) {
    const videoId = this.getVideoId(url);
    let video = null;
    const apiUrl = `https://www.googleapis.com/youtube/v3/videos?id=${videoId}&key=${YOUTUBE_APIKEY}&fields=items(snippet(title,description,thumbnails(default(url)),thumbnails(high(url))))&part=snippet`;

    const instance = axios.create({
      timeout: 60000,
    });

    await instance.get(apiUrl, {}, { headers: { Authorization: '' } })
      .then((response) => {
        if (response.data.items.length !== 0) {
          const { title } = response.data.items[0].snippet;
          const { description } = response.data.items[0].snippet;
          const preview = response.data.items[0].snippet.thumbnails.default.url;
          const previewhq = response.data.items[0].snippet.thumbnails.high.url;

          video = {
            id: videoId,
            url,
            title,
            description,
            preview,
            previewhq,
          };
        }
      });

    return Promise.resolve(video);
  },

};
