export default {
  provider: {
    reviews: {
      criteria: {
        price: {
          name: 'Precio',
          description: 'Ofrece precios justos',
        },
        experience: {
          name: 'Experiencia',
          description: 'Demuestra conocimiento y seguridad',
        },
        quality: {
          name: 'Calidad',
          description: 'Entrega buenos productos y servicios',
        },
        punctuality: {
          name: 'Puntualidad',
          description: 'Cumple los tiempos pactados',
        },
        kindly: {
          name: 'Amabilidad',
          description: 'Atiende siempre con cortesía',
        },
        advisory: {
          name: 'Asesoría',
          description: 'Orienta en la toma de decisiones',
        },
      },
    },
    kpi: {
      customers_count: 'Clientes',
      quotations_count: 'Cotizaciones',
      conversion_rate: 'Tasa de conversión',
      sales_total: 'Ventas',
    },
  },
};
