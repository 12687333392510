import app from './app';
import events from './events';
import frontoffice from './frontoffice';
import services from './services';
import datatable from './datatable';
import quotations from './quotations';
import provider from './provider';
import userEvents from './user-events';

export default {
  ...app,
  ...events,
  ...services,
  ...frontoffice,
  ...datatable,
  ...quotations,
  ...provider,
  ...userEvents,
};
