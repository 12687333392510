<template>
  <section>
    <v-dialog
      content-class="video-player-dialog ma-0"
      width="750px"
      :persistent="persistent"
      v-model="show"
    >
      <iframe
        v-if="show && videoId"
        width="750px"
        class="video-player-frame"
        frameBorder="0"
        allowfullscreen
        mozallowfullscreen
        msallowfullscreen
        oallowfullscreen
        webkitallowfullscreen
        allow="autoplay;"
        :src="`https://www.youtube.com/embed/${videoId}?autoplay=1&enablejsapi=1`"
        v-click-outside="clickOutside"
      />
      <iframe
        v-else-if="show && youtubeUrl"
        width="750px"
        class="video-player-frame"
        frameBorder="0"
        allowfullscreen
        mozallowfullscreen
        msallowfullscreen
        oallowfullscreen
        webkitallowfullscreen
        allow="autoplay;"
        :src="`https://www.youtube.com/embed/${youtubeVideoId}?autoplay=1&enablejsapi=1`"
        v-click-outside="clickOutside"
      />
      <video
        class="video-player-frame"
        v-else-if="show && videoMvp4Url"
        autoplay
        controls
        disablePictureInPicture
        controlsList="nodownload"
        @ended="endedHandler"
        v-click-outside="clickOutside"
      >
        <source
          :src="videoMvp4Url"
          type="video/mp4"
        >
        Your browser does not support HTML5 video.
      </video>
    </v-dialog>
  </section>
</template>

<script>

import FullscreenMixin from '@/mixins/screen';
import YouTube from '../../YouTube';

export default {
  name: 'VideoPlayer',
  mixins: [
    FullscreenMixin,
  ],
  props: {
    initialShow: {
      default: false,
    },
    videoId: {
      default: null,
    },
    youtubeUrl: {
      default: null,
    },
    videoMvp4Url: {
      default: null,
    },
    persistent: {
      default: false,
    },
  },
  data() {
    return {
      show: this.initialShow,
    };
  },
  computed: {
    youtubeVideoId() {
      return YouTube.getVideoId(this.youtubeUrl);
    },
  },
  watch: {
    initialShow() {
      this.show = this.initialShow;
    },
    show(newValue) {
      if (newValue) {
        this.enterFullscreen();
      } else {
        this.$emit('closed');
        this.exitFullscreen();
      }
    },
  },
  methods: {
    endedHandler() {
      this.$emit('ended');
    },
    clickOutside() {
      this.show = false;
    },
  },
};
</script>

<style lang="scss">

  .video-player-dialog {
    border-radius: 0px!important;
    box-shadow: none!important;
    max-height: 100%!important;
  }

</style>

<style lang="scss" scoped>

  .video-player-frame {
    border-radius: 4px;
    height: 500px;

    @media screen and (max-width: 600px) and (orientation: portrait) {
      height: calc(100vw * 0.67)!important;
    }

    @media screen and (max-width: 960px) and (orientation: landscape) {
      height: 100vh;
    }

  }

</style>
