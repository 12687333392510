import Vue from 'vue';
import Container from './Container.vue';
import BoxShadow from './BoxShadow.vue';
import ExpansionSplit from './ExpansionSplit.vue';
import Popper from './Popper.vue';
import Modal from './Modal.vue';
import ModalForm from './ModalForm.vue';
import FormField from './FormField.vue';
import PdfThumbnail from './PDFThumbnail.vue';
import Tooltip from './Tooltip.vue';

Vue.component('e-container', Container);
Vue.component('e-popper', Popper);
Vue.component('box-shadow', BoxShadow);
Vue.component('expansion-split', ExpansionSplit);
Vue.component('modal', Modal);
Vue.component('modal-form', ModalForm);
Vue.component('form-field', FormField);
Vue.component('pdf-thumbnail', PdfThumbnail);
Vue.component('e-tooltip', Tooltip);
