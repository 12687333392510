<template>
  <v-dialog
    v-model="dialog"
    :key="modalKey"
    :width="width"
    :persistent="persistent"
    @keydown.esc="close"
    transition="scale-transition"
    :content-class="`modal-dialog ${contentClass}`">
    <ValidationObserver
      ref="observer"
      v-slot="{ handleSubmit, errors }">
      <form
        :style="`height: ${height}`"
        @submit.prevent="handleSubmit(submit)">
        <div class="modal-left">
          <slot name="left"></slot>
        </div>
        <div
          class="modal-form pa-0"
          :class="{
            'no-header': noHeader,
            'no-actions': noActions,
            'no-gutter': noGutter
          }">
          <div class="header">
            <e-title
              class="modal-title"
              font-size="1.4rem">
              {{title}}
            </e-title>
            <v-btn
              absolute
              small
              text
              fab
              top
              right
              color="grey"
              style="top: 8px; right: 8px"
              :disabled="waiting"
              @click.native="close">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
          <div
            class="content nice-scrollbar"
            :style="`overflow: ${scrollable ? 'auto' : 'hidden'}`"
            ref="content">
            <slot></slot>
            <form-error
              v-if="showErrors"
              :errors="errors"
              @toogleVisible="scrollToEnd">
            </form-error>
          </div>
          <div
            v-if="noActions == false"
            class="actions">
            <slot name="actions">
              <v-spacer></v-spacer>
              <v-btn
                depressed
                text
                right
                class="grey--text px-10"
                :disabled="waiting"
                @click.native="close">
                Cancelar
              </v-btn>
              <v-btn
                right
                type="submit"
                class="white--text px-10"
                :class="submitColor"
                :loading="waiting">
                {{submitLabel}}
              </v-btn>
            </slot>
          </div>
        </div>
        <div class="modal-right">
          <slot name="right"></slot>
        </div>
      </form>
    </ValidationObserver>
  </v-dialog>
</template>

<script>

export default {
  name: 'Modal',
  props: {
    opened: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: null,
    },
    width: {
      type: String,
      default: '700px',
    },
    height: {
      type: String,
      default: '100%',
    },
    persistent: {
      type: Boolean,
      default: true,
    },
    scrollable: {
      type: Boolean,
      default: true,
    },
    showErrors: {
      type: Boolean,
      default: true,
    },
    submitLabel: {
      type: String,
      default: 'Guardar',
    },
    submitColor: {
      type: String,
      default: 'brand-green-darken-1',
    },
    waiting: {
      type: Boolean,
      default: false,
    },
    noHeader: {
      type: Boolean,
      default: false,
    },
    noActions: {
      type: Boolean,
      default: false,
    },
    noGutter: {
      type: Boolean,
      default: true,
    },
    contentClass: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      modalKey: 0,
      dialog: false,
      resolve: null,
      reject: null,
    };
  },
  watch: {
    dialog(val) {
      this.$emit('update:opened', val);
      if (!val) this.close();
    },
  },
  methods: {
    open() {
      this.modalKey += 1;
      this.dialog = true;
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    close() {
      this.modalKey += 1;
      this.$emit('cancel');
      this.resolve(false);
      this.dialog = false;
    },
    submit() {
      this.$emit('submit');
    },
    scrollToEnd() {
      const container = this.$refs.content;
      this.$nextTick(() => {
        container.scrollTop = container.scrollHeight;
      });
    },
  },
};
</script>

<style lang="scss">
.modal-dialog {
  position: relative;
}
</style>

<style lang="scss" scoped>

form {
  display: flex;
  background-color: white !important;
}

.modal-form {
  position: relative;
  background-color: white;
  display: grid;
  height: 100%;
  grid-template-rows: 60px auto 50px;
  grid-template-columns: 1fr;
  grid-gap: 16px;
  padding: 16px;
  flex-grow: 1;

  &.no-header {
    grid-template-rows: 0 auto 50px;
  }

  &.no-actions {
    grid-template-rows: 60px auto;
  }

  &.no-header.no-actions {
    grid-template-rows: 0 auto;
  }

  &.no-gutter {
    grid-gap: 0px;
  }

  &>div {
    padding: 0 16px;
  }

  .header {
    display: inline-flex;
    align-items: center;
  }

  .actions {
    display: inline-flex;
    gap: 12px;
  }
}
</style>
