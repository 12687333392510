import Vue from 'vue';
import * as types from '../mutation-types';

export default {
  namespaced: true,
  state: {
    device: {
      isMobile: false,
      isTablet: false,
    },
    sidebar: {
      opened: false,
      hidden: false,
    },
    login: {
      active: false,
      persistent: false,
    },
    siteEditor: {
      enabled: true,
    },
    snackbar: {
      show: false,
      text: '',
      color: '',
      timeout: 6000,
    },
    mobileSearcher: false,
  },
  getters: {
  },
  mutations: {
    [types.TOGGLE_DEVICE](state, device) {
      state.device.isMobile = device === 'mobile';
      state.device.isTablet = device === 'tablet';
    },
    [types.TOGGLE_SIDEBAR](state, config) {
      if ({}.hasOwnProperty.call(config, 'opened')) {
        state.sidebar.opened = config.opened;
      } else {
        state.sidebar.opened = true;
      }
      if ({}.hasOwnProperty.call(config, 'hidden')) {
        state.sidebar.hidden = config.hidden;
      }
    },
    [types.OPEN_LOGIN](state, { active, persistent, redirectUrl }) {
      if (active === undefined) { active = true; } // eslint-disable-line no-param-reassign
      if (persistent === undefined) { persistent = false; } // eslint-disable-line no-param-reassign
      // set active and persistent state values
      state.login.active = active;
      state.login.persistent = persistent;
      // set cookie 'next'
      // * encodeURIComponent : encodes a URI by replacing each instance of certain characters
      //                        by one, two, three, or four escape sequences
      if (redirectUrl) {
        Vue.cookie.set('next', encodeURIComponent(`action=${redirectUrl}`), 1);
      }
      // console
      // console.log('active =', active);
      // console.log('persistent =', persistent);
      // console.log('redirectUrl =', redirectUrl);
      // console.log('action =', `action=${redirectUrl}`);
      // console.log('action (uri encoded) =', encodeURIComponent(`action=${redirectUrl}`));
    },
    [types.TOGGLE_SNACKBAR](state, message) {
      // eslint-disable-next-line no-param-reassign
      if (message) {
        if (typeof message === 'string') {
          state.snackbar.text = message;
          state.snackbar.color = 'success';
        } else {
          state.snackbar.text = message.text;
          state.snackbar.color = message.color;
          if (message.timeout) {
            state.snackbar.timeout = message.timeout;
          }
        }
        state.snackbar.show = true;
      } else {
        state.snackbar.show = false;
      }
    },
    [types.TOGGLE_MOBILE_SEARCHER](state, mobileSearcher) {
      // eslint-disable-next-line no-param-reassign
      if (mobileSearcher === undefined) { mobileSearcher = true; }
      state.mobileSearcher = mobileSearcher;
    },
    [types.TOGGLE_SITE_EDITOR](state) {
      state.siteEditor.enabled = !state.siteEditor.enabled;
    },
  },
  actions: {
    toggleSidebar({ commit }, config) {
      if (config instanceof Object) {
        commit(types.TOGGLE_SIDEBAR, config);
      }
    },
    toggleDevice({ commit }, device) {
      commit(types.TOGGLE_DEVICE, device);
    },
    openLogin({ commit }, { persistent, redirectUrl } = {}) {
      commit(types.OPEN_LOGIN, { active: true, persistent, redirectUrl });
    },
    closeLogin({ commit }) {
      commit(types.OPEN_LOGIN, { active: false });
    },
    snackbar({ commit }, message) {
      commit(types.TOGGLE_SNACKBAR, message);
    },
    hideSnackbar({ commit }) {
      commit(types.TOGGLE_SNACKBAR, null);
    },
    toggleMobileSearcher({ commit }, mobileSearcher) {
      commit(types.TOGGLE_MOBILE_SEARCHER, mobileSearcher);
    },
    toggleSiteEditor({ commit }) {
      commit(types.TOGGLE_SITE_EDITOR);
    },
  },
};
