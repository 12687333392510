<template>
  <v-app
    :class="{
      'grey': grey,
      'mobile': isMobile,
      'mobile-fullheight': mobileFullHeight
    }"
    v-resize="onResize">
    <nprogress-container />
    <header-bar />
    <main>
      <transition
        mode="out-in"
        enter-active-class="fadeIn"
        leave-active-class="fadeOut"
        appear>
        <router-view class="router-view animated"></router-view>
      </transition>
    </main>
    <footer-bar v-if="!mobileFullHeight" />
    <login-modal />
    <snackbar />
    <whatsapp-button />
  </v-app>
</template>

<script>

import Vue from 'vue';
import { mapActions } from 'vuex';
import NprogressContainer from 'vue-nprogress/src/NprogressContainer.vue';
import Utils from './Utils';

const HeaderBar = () => import(/* webpackChunkName: "headerbar" */ '@/components/Layout/Header');
const FooterBar = () => import(/* webpackChunkName: "footerbar" */ '@/components/Layout/Footer');
const LoginModal = () => import(/* webpackChunkName: "loginmodal" */ '@/components/Auth/LoginModal.vue');
const WhatsappButton = () => import(/* webpackChunkName: "whatsappbutton" */ '@/components/Shared/WhatsappButton.vue');

export default {
  name: 'App',
  data() {
    return {
      layout: 'div',
    };
  },
  computed: {
    grey() {
      let result = false;
      const routes = [
        'providersite',
        'search',
        'providers',
        'affiliation',
        'pricing',
        'suscriptionplans',
        'createevent',
        'events',
        'events_myevents',
        'events_searcher',
        'events_searcher_detail',
        'events_detail',
        'quotations',
        'myaccount',
      ];
      if (routes.some((p) => p === this.$route.name)) {
        result = true;
      }
      return result;
    },
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    mobileFullHeight() {
      const routes = [
        'affiliation',
        'createevent',
      ];
      return this.$vuetify.breakpoint.smAndDown && routes.some((p) => p === this.$route.name);
    },
  },
  beforeMount() {
    this.refreshLocations();
    this.refreshKeywords();
    this.refreshServiceTypes();
    Utils.loadFacebook();
  },
  mounted() {
    const iOs = /iPhone|iPad|iPod/i.test(navigator.userAgent);
    if (iOs) {
      const viewport = document.getElementById('viewport');
      viewport.setAttribute('content', 'width=device-width,initial-scale=1,user-scalable=0');
    }
  },
  created() {
    // set default timezone
    this.$moment.locale('es', {
      parentLocale: 'es',
      months: 'Enero_Febrero_Marzo_Abril_Mayo_Junio_Julio_Agosto_Septiembre_Octubre_Noviembre_Diciembre'.split('_'),
      monthsShort: 'Ene_Feb_Mar_Abr_May_Jun_Jul_Ago_Sep_Oct_Nov_Dic'.split('_'),
      weekdays: 'Domingo_Lunes_Martes_Miércoles_Jueves_Viernes_Sábado'.split('_'),
      weekdaysShort: 'Dom._Lun._Mar._Mié._Jue._Vie._Sáb.'.split('_'),
      weekdaysMin: 'Do_Lu_Ma_Mi_Ju_Vi_Sá'.split('_'),
    });
    // set isMobile
    Vue.prototype.$isMobile = this.$vuetify.breakpoint.smAndDown;
  },
  watch: {
    $route(to, from) {
      if (to !== from) {
        this.refreshProviderSite();
      }
    },
    mobileFullHeight(value) {
      const html = document.getElementsByTagName('html')[0];
      if (value) {
        html.style.overscrollBehaviorY = 'none';
      } else {
        html.style.overscrollBehaviorY = null;
      }
    },
  },
  methods: {
    ...mapActions('eventeros/', [
      'refreshProviderSite',
      'refreshServiceTypes',
      'refreshLocations',
      'refreshKeywords',
    ]),
    onResize() {
      Vue.prototype.$isMobile = this.$vuetify.breakpoint.smAndDown;
    },
  },
  components: {
    HeaderBar,
    FooterBar,
    LoginModal,
    WhatsappButton,
    NprogressContainer,
  },
};
</script>

<style lang="scss">

@import '@/styles/global';

#app.grey {
  background: #f6f6f6;
}

.v-application {
  min-width: 320px;

  .v-application--wrap {
    // use 100% instead of 100vh to prevent unwanted scrolling in mobile
    min-height: 100%!important;
  }

  &.mobile-fullheight {
    height: 100%;

    main {
      display: flex;
      flex-direction: column;
      flex: 1;
      overflow: hidden;
    }
  }
}
</style>
