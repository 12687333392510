import { mapActions, mapState } from 'vuex';

const mixin = {
  computed: {
    ...mapState('eventeros/', ['serviceTypes']),
    categories() {
      return this.getCategories(this.serviceTypes);
    },
  },
  created() {
    this.refreshServiceTypes();
  },
  methods: {
    ...mapActions('eventeros/', ['refreshServiceTypes']),
    getServicesByIds(ids) {
      let services = [];
      if (Array.isArray(ids)) {
        services = this.serviceTypes
          .filter((p) => ids.includes(p.id));
      }
      return services;
    },
    getServicesByGroup(groupName) {
      return this.serviceTypes
        .filter((p) => p.group_name === groupName)
        .sort((a, b) => a.type_name.localeCompare(b.type_name)); // sorted by type_name
    },
    getCategories(services) {
      let categories = services
        .map((p) => p.group)
        .filter((value, index, self) => self.indexOf(value) === index); // distinct;
      categories = categories.map((p) => {
        const filteredServices = services
          .filter((s) => s.group === p).map((s) => {
            const service = {
              ...s,
              ...{ selected: false },
            };
            return service;
          })
          .sort((a, b) => a.type_name.localeCompare(b.type_name));
        const group = {
          group: p,
          group_name: services.find((g) => g.group === p).group_name,
          services: filteredServices,
          selected: false,
          count: filteredServices.length,
        };
        return group;
      });
      return categories
        .sort((a, b) => a.group_name.localeCompare(b.group_name));
    },
  },
};

export default mixin;
