<template>
  <v-tooltip
    :left="left"
    :right="right"
    :top="top"
    :bottom="bottom"
    :color="color">
    <template v-slot:activator="{ on, attrs }">
      <div
        class="tooltip-content"
        v-bind="attrs"
        v-on="on">
        <slot></slot>
      </div>
    </template>
    <slot name="label">
      <span v-html="label"></span>
    </slot>
  </v-tooltip>
</template>

<script>
export default {
  name: 'Tooltip',
  props: {
    label: {
      type: String,
      default: 'Tooltip',
    },
    color: {
      default: 'black',
    },
    top: {
      type: Boolean,
      default: false,
    },
    bottom: {
      type: Boolean,
      default: false,
    },
    left: {
      type: Boolean,
      default: false,
    },
    right: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.tooltip-content {
  display: inline;
}
</style>
