<template>
  <div
    class="e-subtitle"
    :style="`
      font-size: ${fontSize};
      font-weight: ${fontWeight};
      color: ${color};`">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'Title',
  props: {
    fontSize: {
      type: String,
      default: '0.9rem',
    },
    fontWeight: {
      type: Number,
      default: 500,
    },
    color: {
      type: String,
      default: 'grey',
    },
  },
};
</script>

<style lang="scss" scoped>
.e-subtitle {
  margin-bottom: 2px;
}
</style>
