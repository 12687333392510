<template>
  <v-alert
    :icon="false"
    class="text-body-1 ma-0"
    type="error"
    border="left"
    v-if="hasErrors">
    <ul v-if="showDetails">
      <template
        v-for="(error, index) in errors">
        <li
          :key="index"
          v-if="error.length > 0">
          <div>
            {{ error[0] }}
          </div>
        </li>
      </template>
    </ul>
    <div
      v-else>
      {{ defaultMessage }}
    </div>
  </v-alert>
</template>

<script>
export default {
  name: 'FormError',
  props: {
    errors: {
      type: Object,
      required: false,
      default: null,
    },
    showDetails: {
      type: Boolean,
      required: false,
      default: false,
    },
    defaultMessage: {
      type: String,
      required: false,
      default: 'Por favor, complete todos los campos requeridos',
    },
  },
  computed: {
    hasErrors() {
      return (this.errors && Object.values(this.errors).flat().length > 0);
    },
  },
  watch: {
    hasErrors() {
      this.$emit('toogleVisible', this.hasErrors);
    },
  },
};
</script>
