<template>
  <v-switch
    inset
    class="e-switch ma-0"
    :color="color"
    :disabled="disabled"
    v-on="$listeners"
    v-model="enabled"
    :hide-details="hideDetails"
    @change="$emit('input', enabled)"
  >
    <template v-slot:label v-if="!hideLabel">
      <span class="switch-label black--text">
        {{ label }}
      </span>
    </template>
  </v-switch>
</template>

<script>
export default {
  name: 'ToggleSwitch',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: null,
    },
    hideLabel: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: 'brand-green',
    },
    hideDetails: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      enabled: this.value,
    };
  },
  watch: {
    value() {
      this.enabled = this.value;
    },
  },
};
</script>

<style lang="scss">
.e-switch {
  .v-input--selection-controls__input {
    margin-right: 0;
  }

  .switch-label {
    margin-left: 2px;
  }
}
</style>
