export default {
  datatable: {
    close: 'Cerrar',
    dataIterator: {
      pageText: '{0}-{1} de {2}',
      noResultsText: 'No matching records found',
      loadingText: 'Cargando datos...',
    },
    dataTable: {
      itemsPerPageText: 'Filas por página:',
      ariaLabel: {
        sortDescending: ': Sorted descending. Activate to remove sorting.',
        sortAscending: ': Sorted ascending. Activate to sort descending.',
        sortNone: ': Not sorted. Activate to sort ascending.',
      },
      sortBy: 'Ordenar por',
    },
    dataFooter: {
      pageText: '{0}-{1} de {2}',
      itemsPerPageText: 'Filas por página:',
      itemsPerPageAll: 'Todo',
      nextPage: 'Página siguiente',
      prevPage: 'Página anterior',
      firstPage: 'Primera página',
      lastPage: 'Última página',
    },
    datePicker: {
      itemsSelected: '{0} seleccionados',
    },
    noDataText: 'No hay datos disponibles',
    noResultsText: 'No se encontraron datos',
    carousel: {
      prev: 'Previous visual',
      next: 'Next visual',
    },
    calendar: {
      moreEvents: '{0} mas',
    },
  },
};
