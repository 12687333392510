// https://vee-validate.logaretm.com/v3/guide/rules.html#rules

import * as rules from 'vee-validate/dist/rules';

const validateImage = (file, width, height) => {
  const URL = window.URL || window.webkitURL;
  return new Promise((resolve) => {
    const image = new Image();
    image.onerror = () => resolve({ valid: false });
    image.onload = () => resolve({
      valid: image.width >= Number(width) && image.height >= Number(height),
    });

    image.src = URL.createObjectURL(file);
  });
};

export default {
  ...rules,
  min_dimensions: {
    getMessage: (field, [width, height]) => `Su ${field} no puede ser menor de ${width} x ${height} pixeles.`,
    validate: (files, [width, height]) => {
      const list = [];
      for (let i = 0; i < files.length; i += 1) {
        // if file is not an image, reject.
        if (!/\.(jpg|svg|jpeg|png|bmp|gif)$/i.test(files[i].name)) {
          return false;
        }
        list.push(files[i]);
      }
      return Promise.all(list.map((file) => validateImage(file, width, height)));
    },
  },
};
