<template>
  <e-select
    :filled="filled"
    :label="label"
    :name="name"
    :rules="rules"
    :mode="mode"
    :items="orderedDepartments"
    item-text="name"
    item-value="id"
    v-model="innerValue"
    v-on="$listeners">
    <div slot="label">
      <i v-if="icon" :class="`${icon} subheading`"></i>
      {{label}}
    </div>
    <!-- pass named slots -->
    <template
      v-for="(slot, name) in $slots"
      :slot="name">
      <slot v-if="name === 'default'"></slot>
      <slot v-if="name !== 'default'" :name="name"></slot>
    </template>
    <!-- pass scoped slots -->
    <template
      v-for="(slot, name) in $scopedSlots"
      :slot="name"
      slot-scope="props">
      <slot :name="name" v-bind="props"></slot>
    </template>
  </e-select>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  name: 'SelectDepartments',
  inheritAttrs: false,
  props: {
    label: {
      type: String,
      required: false,
      default: 'Seleccionar deparamento',
    },
    icon: {
      type: [String, Boolean],
      required: false,
      default: 'fa fa-location-2',
    },
    name: {
      type: String,
      required: true,
      default: null,
    },
    value: {
      type: [Number, String, Array, Object],
      required: false,
      default: null,
    },
    rules: [String],
    mode: {
      type: String,
      default: 'eager',
    },
    filled: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      innerValue: this.value,
    };
  },
  computed: {
    ...mapState('eventeros/', ['locations']),
    orderedDepartments() {
      const departments = this.locations
        .filter((o) => o.type === 'region')
        .sort((a, b) => a.name.localeCompare(b.name));
      return departments;
    },
  },
  methods: {
    ...mapActions('eventeros/', ['refreshLocations']),
  },
};
</script>
