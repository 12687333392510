/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */

import router from '../router';

const UtilsPlugin = {
  install(Vue, options) {
    // encodeBase64
    Vue.prototype.$encodeBase64 = (object) => {
      const json = JSON.stringify(object);
      return window.btoa(json);
    };

    // decodeBase64
    Vue.prototype.$decodeBase64 = (base64) => {
      const json = window.atob(base64);
      return JSON.parse(json);
    };

    // deepCopy
    Vue.prototype.$deepCopy = (object) => JSON.parse(JSON.stringify(object));

    // waitFor
    Vue.prototype.$waitFor = (ms) => new Promise((r) => setTimeout(r, ms));

    // redirect
    Vue.prototype.$redirect = (name, params, query) => {
      const { currentRoute } = router;
      if (currentRoute.name !== name
          || JSON.stringify(currentRoute.params) !== JSON.stringify(params ?? {})
          || JSON.stringify(currentRoute.query) !== JSON.stringify(query ?? {})) {
        router.push({ name, params, query });
      }
    };

    // isEqual
    Vue.prototype.$isEqual = (a, b) => JSON.stringify(a) === JSON.stringify(b);
  },
};

export default UtilsPlugin;
