<template>
  <e-multi-select
    :name="name"
    :rules="rules"
    :data="categories"
    :label="label"
    :max-items="maxItems"
    :hide-details="hideDetails"
    item-text="group_name"
    item-value="group"
    v-model="innerValue"
    v-on="$listeners">
    <!-- Pass on all named slots -->
    <slot
      v-for="slot in Object.keys($slots)"
      :name="slot"
      :slot="slot"
    />
    <!-- Pass on all scoped slots -->
    <template
      v-for="slot in Object.keys($scopedSlots)"
      :slot="slot"
      slot-scope="scope">
      <slot :name="slot" v-bind="scope"/>
    </template>
  </e-multi-select>
</template>

<script>

import services from '@/mixins/services';

export default {
  name: 'MultiSelectCategories',
  mixins: [services],
  inheritAttrs: false,
  props: {
    name: {
      type: String,
      required: false,
      default: 'categories',
    },
    label: {
      type: String,
      required: false,
      default: 'Seleccionar categorias',
    },
    value: null,
    rules: [String],
    maxItems: {
      type: Number,
      required: false,
      default: 1,
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      innerValue: this.value,
    };
  },
  watch: {
    value(val) {
      this.innerValue = val;
    },
  },
};
</script>
