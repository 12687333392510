<template>
  <div
    class="wizard-stepper-modal"
    :class="mainColor"
    v-if="steps.length > 0"
    :style="`width: ${width}`">
    <e-title
      class="stepper-title"
      font-size="1.6rem">
      <span v-html="title"></span>
    </e-title>
    <div
      class="stepper-wrapper">
      <v-stepper
        v-model="step"
        vertical
        class="rounded-0 elevation-0 transparent">
        <template
          v-for="(item, index) in steps">
          <v-stepper-step
            :key="index"
            :step="index + 1"
            :complete="step > index + 1">
            <div class="step-title">
              {{ item.title }}
            </div>
            <div class="step-description">
              {{ item.description }}
            </div>
          </v-stepper-step>
        </template>
      </v-stepper>
      <v-divider></v-divider>
    </div>
  </div>
</template>

<script>

export default {
  name: 'WizardStepperModal',
  props: {
    currentStep: {
      default: 1,
    },
    steps: {
      default: [],
    },
    title: {
      type: String,
      default: 'Título',
    },
    mainColor: {
      default: 'brand_purple', // brand_blue
    },
    width: {
      type: String,
      default: '280px',
    },
  },
  data() {
    return {
      step: this.currentStep,
    };
  },
  watch: {
    currentStep() {
      this.step = this.currentStep;
    },
  },
};
</script>

<style lang="scss">
  .wizard-stepper-modal  {
    display: flex;
    flex-direction: column;
    height: 100%;

    .stepper-wrapper {
      position: relative;
    }

    .stepper-title,
    .stepper-wrapper {
      color: white;
      flex: 1 1 auto;
    }

    .stepper-title {
      font-size: 1.4rem;
      padding: 24px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      line-height: 24px;
    }

    .stepper-wrapper {
      .v-stepper {
        z-index: 1;
      }

      .v-stepper__step {
        padding: 0 24px 45px;
      }

      .v-stepper__step__step {
        width: 40px;
        height: 40px;
        font-family: "Work Sans", sans-serif !important;
        font-weight: bold;
        font-size: 1.1rem;
      }

      .v-stepper__label {
        color: white!important;

        .step-title {
          font-weight: 700;
          margin-bottom: 6px;
        }

        .step-description {
          font-size: 0.8rem;
        }
      }

      .v-divider {
        position: absolute;
        width: calc(100% - 30px);
        top: 140px;
        left: -82px;
        z-index: 0;
        border-color: #e3e3e3;
        border-style: dotted;
        transform: rotate(90deg);
      }
    }

    &.brand_purple {
      background-color: $brand-purple-lighten-1;
      color: white;

      $active: $brand-blue-darken-1!default;
      $complete: $brand-purple-lighten-2!default;
      $inactive: #E6E9F0!default;

      .v-stepper__step.v-stepper__step--active {
        .v-stepper__step__step {
          background: $active!important;
        }
      }

      .v-stepper__step.v-stepper__step--complete {
        .v-stepper__step__step {
          background: $complete!important;
        }
      }

      .v-stepper__step:not(.v-stepper__step--active):not(.v-stepper__step--complete) {
        .v-stepper__step__step {
          color: $brand-purple-darken-1;
          background: $inactive!important;
        }
      }
    }

    &.brand_blue {
      background-color: $brand-blue;
      color: white;

      $active: $brand-purple-lighten-1!default;
      $complete: $brand-blue-lighten-2!default;
      $inactive: #E6E9F0!default;

      .v-stepper__step.v-stepper__step--active {
        .v-stepper__step__step {
          background: $active!important;
        }
      }

      .v-stepper__step.v-stepper__step--complete {
        .v-stepper__step__step {
          background: $complete!important;
        }
      }

      .v-stepper__step:not(.v-stepper__step--active):not(.v-stepper__step--complete) {
        .v-stepper__step__step {
          color: $brand-blue-darken-1;
          background: $inactive!important;
        }
      }
    }

  }
</style>
