<template>
  <v-row class="form-field">
    <v-col :cols="cols">
      <slot name="label">
        {{ label }}
      </slot>
    </v-col>
    <v-col :cols="12 - cols">
      <slot>
        <div v-if="value">
          <div v-if="format === 'none'">
            {{ value }}
          </div>
          <div v-if="format === 'link'">
            <a :href="value" target="_blank">{{ value }}</a>
          </div>
          <div v-if="format === 'date'">
            {{ value | moment("DD/MM/YYYY") }}
          </div>
          <div v-if="format === 'datetime'">
            {{ value | moment("DD/MM/YYYY hh:mm a") }}
          </div>
        </div>
      </slot>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'FormField',
  props: {
    label: {
      type: String,
      default: null,
    },
    value: {
      type: [String, Number, Boolean],
      default: null,
    },
    format: {
      type: String,
      default: 'none',
    },
    labelCols: {
      type: Number,
      default: 3,
    },
  },
  computed: {
    cols() {
      return this.$parent.labelCols ?? this.labelCols;
    },
  },
};
</script>
